import React, { useEffect } from 'react'
import {
  Container,
  Divider,
  Grid,
  Image,
  Header,
  Icon,
  Segment,
  Card,
  List
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PageVideoResources = () => {
    useEffect(() => {
        document.title = 'Video Resources - Fascomp Electronic Hardware';
      }, []);

    return (
    <Container>
      <Header as='h2' dividing textAlign='center'>
        <Icon name='video' />
        <Header.Content>Video Resources</Header.Content>
      </Header>

        <div class="video-container">
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/4q9alI26Bxk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/E5ZEmoygGHg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/pgpIkj_WfG0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/n3_tu4u-jds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/yz-EYjitqHI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/o4R8eZp446I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/DEN6wgzFeBA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/8SOaQmRKZk0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/D8OJUta-_YI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.youtube.com/embed/ylVEckPkcOQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        
      <ScrollToTopOnMount />
    </Container>



    )
}

export default PageVideoResources