import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import {
  Container,
  Table,
  Icon,
  Header,
  Label,
  Message
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PageDepthOfThreadMF = () => {

  useEffect(() => {
    document.title = 'Depth of Thread (Male-Female Standoffs) - Fascomp Electronic Hardware';
  }, []);

  return (
  <div>
    <Container>
      <Header as='h2' dividing textAlign='center'>
        <Icon name='info circle' />
        <Header.Content>Tap Depths for Male-Female Standoffs</Header.Content>
      </Header>

      <Table celled unstackable columns={9}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="11">American Sizes</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Body Length</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">3/16</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">1/4</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">5/16</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">3/8</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">7/16</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">1/2</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">9/16</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">5/8</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">11/16</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">3/4</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Thread</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="10">Minimum Depth</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="center">2-56</Table.Cell>
            <Table.Cell textAlign="center">.095</Table.Cell>
            <Table.Cell textAlign="center">.150</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">4-40</Table.Cell>
            <Table.Cell textAlign="center">.100</Table.Cell>
            <Table.Cell textAlign="center">.150</Table.Cell>
            <Table.Cell textAlign="center">.200</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">6-32</Table.Cell>
            <Table.Cell textAlign="center">.090</Table.Cell>
            <Table.Cell textAlign="center">.130</Table.Cell>
            <Table.Cell textAlign="center">.190</Table.Cell>
            <Table.Cell textAlign="center">.250</Table.Cell>
            <Table.Cell textAlign="center">.300</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">8-32</Table.Cell>
            <Table.Cell textAlign="center">.090</Table.Cell>
            <Table.Cell textAlign="center">.130</Table.Cell>
            <Table.Cell textAlign="center">.190</Table.Cell>
            <Table.Cell textAlign="center">.250</Table.Cell>
            <Table.Cell textAlign="center">.300</Table.Cell>
            <Table.Cell textAlign="center">.355</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">10-32</Table.Cell>
            <Table.Cell textAlign="center">.090</Table.Cell>
            <Table.Cell textAlign="center">.125</Table.Cell>
            <Table.Cell textAlign="center">.190</Table.Cell>
            <Table.Cell textAlign="center">.250</Table.Cell>
            <Table.Cell textAlign="center">.300</Table.Cell>
            <Table.Cell textAlign="center">.355</Table.Cell>
            <Table.Cell textAlign="center">.434</Table.Cell>
            <Table.Cell textAlign="center">.475</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">12-24</Table.Cell>
            <Table.Cell textAlign="center">.070</Table.Cell>
            <Table.Cell textAlign="center">.110</Table.Cell>
            <Table.Cell textAlign="center">.160</Table.Cell>
            <Table.Cell textAlign="center">.195</Table.Cell>
            <Table.Cell textAlign="center">.250</Table.Cell>
            <Table.Cell textAlign="center">.335</Table.Cell>
            <Table.Cell textAlign="center">.424</Table.Cell>
            <Table.Cell textAlign="center">.465</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">25-20</Table.Cell>
            <Table.Cell textAlign="center">.060</Table.Cell>
            <Table.Cell textAlign="center">.090</Table.Cell>
            <Table.Cell textAlign="center">.135</Table.Cell>
            <Table.Cell textAlign="center">.160</Table.Cell>
            <Table.Cell textAlign="center">.250</Table.Cell>
            <Table.Cell textAlign="center">.310</Table.Cell>
            <Table.Cell textAlign="center">.365</Table.Cell>
            <Table.Cell textAlign="center">.430</Table.Cell>
            <Table.Cell textAlign="center">.475</Table.Cell>
            <Table.Cell textAlign="center">.553</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">31-18</Table.Cell>
            <Table.Cell textAlign="center">.060</Table.Cell>
            <Table.Cell textAlign="center">.080</Table.Cell>
            <Table.Cell textAlign="center">.125</Table.Cell>
            <Table.Cell textAlign="center">.150</Table.Cell>
            <Table.Cell textAlign="center">.220</Table.Cell>
            <Table.Cell textAlign="center">.280</Table.Cell>
            <Table.Cell textAlign="center">.335</Table.Cell>
            <Table.Cell textAlign="center">.400</Table.Cell>
            <Table.Cell textAlign="center">.475</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table celled unstackable columns={9}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="17">Metric</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Body Length</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">5mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">6mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">7mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">8mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">9mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">10mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">11mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">12mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">13mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">14mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">15mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">16mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">17mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">18mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">19mm</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">20mm</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Thread</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="16">Minimum Depth</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="center">2.5 x 0.45</Table.Cell>
            <Table.Cell textAlign="center">2.5</Table.Cell>
            <Table.Cell textAlign="center">3.5</Table.Cell>
            <Table.Cell textAlign="center">4.5</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">3 x 0.5</Table.Cell>
            <Table.Cell textAlign="center">2.6</Table.Cell>
            <Table.Cell textAlign="center">3.6</Table.Cell>
            <Table.Cell textAlign="center">4.6</Table.Cell>
            <Table.Cell textAlign="center">5.6</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">3.5 x 0.6</Table.Cell>
            <Table.Cell textAlign="center">1.7</Table.Cell>
            <Table.Cell textAlign="center">2.7</Table.Cell>
            <Table.Cell textAlign="center">3.7</Table.Cell>
            <Table.Cell textAlign="center">4.7</Table.Cell>
            <Table.Cell textAlign="center">5.7</Table.Cell>
            <Table.Cell textAlign="center">6.7</Table.Cell>
            <Table.Cell textAlign="center">7.7</Table.Cell>
            <Table.Cell textAlign="center">8.7</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">4 x 0.7</Table.Cell>
            <Table.Cell textAlign="center">1.3</Table.Cell>
            <Table.Cell textAlign="center">2.5</Table.Cell>
            <Table.Cell textAlign="center">3.3</Table.Cell>
            <Table.Cell textAlign="center">4.3</Table.Cell>
            <Table.Cell textAlign="center">5.3</Table.Cell>
            <Table.Cell textAlign="center">6.3</Table.Cell>
            <Table.Cell textAlign="center">7.3</Table.Cell>
            <Table.Cell textAlign="center">8.3</Table.Cell>
            <Table.Cell textAlign="center">9.3</Table.Cell>
            <Table.Cell textAlign="center">10.3</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">5 x 0.8</Table.Cell>
            <Table.Cell textAlign="center">1.2</Table.Cell>
            <Table.Cell textAlign="center">2.2</Table.Cell>
            <Table.Cell textAlign="center">3.2</Table.Cell>
            <Table.Cell textAlign="center">4.2</Table.Cell>
            <Table.Cell textAlign="center">5.2</Table.Cell>
            <Table.Cell textAlign="center">6.2</Table.Cell>
            <Table.Cell textAlign="center">7.2</Table.Cell>
            <Table.Cell textAlign="center">8.2</Table.Cell>
            <Table.Cell textAlign="center">9.2</Table.Cell>
            <Table.Cell textAlign="center">10.2</Table.Cell>
            <Table.Cell textAlign="center">11.2</Table.Cell>
            <Table.Cell textAlign="center">12.2</Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
            <Table.Cell textAlign="center"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">6 x 1.0</Table.Cell>
            <Table.Cell textAlign="center">1.1</Table.Cell>
            <Table.Cell textAlign="center">1.2</Table.Cell>
            <Table.Cell textAlign="center">1.4</Table.Cell>
            <Table.Cell textAlign="center">2.4</Table.Cell>
            <Table.Cell textAlign="center">3.4</Table.Cell>
            <Table.Cell textAlign="center">4.4</Table.Cell>
            <Table.Cell textAlign="center">5.4</Table.Cell>
            <Table.Cell textAlign="center">6.4</Table.Cell>
            <Table.Cell textAlign="center">7.4</Table.Cell>
            <Table.Cell textAlign="center">8.4</Table.Cell>
            <Table.Cell textAlign="center">9.4</Table.Cell>
            <Table.Cell textAlign="center">10.4</Table.Cell>
            <Table.Cell textAlign="center">11.4</Table.Cell>
            <Table.Cell textAlign="center">12.4</Table.Cell>
            <Table.Cell textAlign="center">13.4</Table.Cell>
            <Table.Cell textAlign="center">14.4</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">8 x 1.25</Table.Cell>
            <Table.Cell textAlign="center">1.0</Table.Cell>
            <Table.Cell textAlign="center">1.0</Table.Cell>
            <Table.Cell textAlign="center">1.3</Table.Cell>
            <Table.Cell textAlign="center">1.8</Table.Cell>
            <Table.Cell textAlign="center">2.8</Table.Cell>
            <Table.Cell textAlign="center">3.8</Table.Cell>
            <Table.Cell textAlign="center">4.8</Table.Cell>
            <Table.Cell textAlign="center">5.8</Table.Cell>
            <Table.Cell textAlign="center">6.8</Table.Cell>
            <Table.Cell textAlign="center">7.8</Table.Cell>
            <Table.Cell textAlign="center">8.8</Table.Cell>
            <Table.Cell textAlign="center">9.8</Table.Cell>
            <Table.Cell textAlign="center">10.8</Table.Cell>
            <Table.Cell textAlign="center">11.8</Table.Cell>
            <Table.Cell textAlign="center">12.8</Table.Cell>
            <Table.Cell textAlign="center">13.8</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Message floating>
        <p>Brass and Steel parts are manufactured to a “pre-plate condition”.<br />
        Therefore, if they are not subsequently plated, they may accept not-go gauge.</p>
        <p>Please call sales office for sizes not listed.</p>
      </Message>
    </Container>
    <ScrollToTopOnMount />
  </div>
  )
}


          

export default PageDepthOfThreadMF