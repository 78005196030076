import { when, reaction } from "mobx"
import { types, getParent, getSnapshot, applySnapshot, flow } from "mobx-state-tree"
import { PartSelected } from "./PartGroupStore"
import AppError from "../modules/apperror"


const UserEvent = types
    .model("UserEvent", {
      userId: types.string,
      username: types.optional(types.string, ''),
      firstName: types.optional(types.string, ''),
      lastName: types.optional(types.string, ''),
      company: types.optional(types.string, ''),
      description: types.optional(types.string, ''),
      dateEvent: types.Date
    })
    .views(self => ({

    }));

const QuoteEntry = types
    .model("QuoteEntry", {
      quantity1: '',
      quantity2: '',
      quantity3: '',
      price1: types.maybe(types.string),
      price2: types.maybe(types.string),
      price3: types.maybe(types.string),
      platingPrice1: types.maybe(types.string),
      platingPrice2: types.maybe(types.string),
      platingPrice3: types.maybe(types.string),
      delivery1: types.maybe(types.string),
      delivery2: types.maybe(types.string),
      delivery3: types.maybe(types.string),
      adminComments: types.optional(types.string, ''),
      part: PartSelected
    })
    .views(self => ({

    }))
    .actions(self => ({
      setQuantity1(amount) {
        self.quantity1 = amount
      },
      setQuantity2(amount) {
        self.quantity2 = amount
      },
      setQuantity3(amount) {
        self.quantity3 = amount
      },
      setPrice1(value) {
        self.price1 = value
      },
      setPrice2(value) {
        self.price2 = value
      },
      setPrice3(value) {
        self.price3 = value
      },
      setPlatingPrice1(value) {
        self.platingPrice1 = value
      },
      setPlatingPrice2(value) {
        self.platingPrice2 = value
      },
      setPlatingPrice3(value) {
        self.platingPrice3 = value
      },
      setDelivery1(value) {
        self.delivery1 = value
      },
      setDelivery2(value) {
        self.delivery2 = value
      },
      setDelivery3(value) {
        self.delivery3 = value
      },
      setAdminComments(value) {
        self.adminComments = value
      }
    }))

export const Quote = types
    .model("Quote", {
      id: types.identifier(),
      company: types.optional(types.string, ''),
      firstName: types.optional(types.string, ''),
      lastName: types.optional(types.string, ''),
      phone: types.optional(types.string, ''),
      email: types.optional(types.string, ''),
      quoteType: types.optional(types.string, ''),
      salesRepEmail: types.optional(types.string, ''),
      entries: types.array(QuoteEntry),
      comments: types.optional(types.string, ''),
      quoteRequested: types.optional(types.boolean, false),
      userAssigned: types.maybe(UserEvent),
      openHistory: types.optional(types.array(UserEvent), []),
      dateCreated: types.optional(types.string, ''),
    })
    .views(self => ({
      get shop() {
        return getParent(getParent(self));
      },
      get parsedDateCreated() {
        return new Date(Date.parse(self.dateCreated));
      },
      get quoted() {
        let ret = true;
        self.entries.forEach(entry => {
          if (entry && parseInt(entry.quantity1) > 0) {
            if (!entry.price1 || entry.price1.length < 1 || isNaN(entry.price1))
              ret = false;
          }
          if (entry && parseInt(entry.quantity2) > 0) {
            if (!entry.price2 || entry.price2.length < 1 || isNaN(entry.price2))
              ret = false;
          }
          if (entry && parseInt(entry.quantity3) > 0) {
            if (!entry.price3 || entry.price3.length < 1 || isNaN(entry.price3))
              ret = false;
          }
        });

        return ret;
      },
      get assigned() {
        return self.userAssigned ? true : false;
      }

    }))
    .actions(self => {
      const setCompany = value => {
        self.company = value
      };

      const setFirstName = value => {
        self.firstName = value
      };

      const setLastName = value => {
        self.lastName = value
      };

      const setPhone = value => {
        self.phone = value
      };

      const setEmail = value => {
        self.email = value
      };

      const setQuoteType = value => {
        self.quoteType = value
      };
      
      const setSalesRepEmail = value => {
        self.salesRepEmail = value
      };

      const setEntries = value => {
        self.entries = value
      };

      const setComments = value => {
        self.comments = value
      };

      const setQuoteRequested = value => {
        self.quoteRequested = value
      };

      const setUserAssigned = value => {
        self.userAssigned = value
      };

      const setOpenHistory = value => {
        self.openHistory = value
      };

      const save = flow(function* save() {

        const response = yield fetch('/api/rfq/update', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(getSnapshot(self)),
          headers:{
            'Content-Type': 'application/json'
          }
        });

        const json = yield response.json();

        if (!response.ok) {
          if (response.status && (response.status == 401 || response.status == 403)) {
            self.shop.auth.setAuthRequested(true, () => {
                self.save();
            });
          }
          else {
            throw Object.assign(new AppError, json.errors[0]);
          }
        }

        return json;
      });

      const assign = flow(function* assign(userId) {

        const response = yield fetch('/api/rfq/assign', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify({
            id: self.id,
            userId
          }),
          headers:{
            'Content-Type': 'application/json'
          }
        });

        const json = yield response.json();

        if (!response.ok) {
          if (response.status && (response.status == 401 || response.status == 403)) {
            self.shop.auth.setAuthRequested(true, () => {
                self.assign(userId);
            });
          }
          else {
            throw Object.assign(new AppError, json.errors[0]);
          }
        }

        return json;
      });

      const send = flow(function* send() {

        const response = yield fetch('/api/rfq/send', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify({
            id: self.id
          }),
          headers:{
            'Content-Type': 'application/json'
          }
        });

        const json = yield response.json();

        if (!response.ok) {
          if (response.status && (response.status == 401 || response.status == 403)) {
            self.shop.auth.setAuthRequested(true, () => {
                self.send();
            });
          }
          else {
            throw Object.assign(new AppError, json.errors[0]);
          }
        }

        return json;
      

      });
   
    
      return {
        setCompany,
        setFirstName,
        setLastName,
        setEmail,
        setQuoteType,
        setSalesRepEmail,
        setPhone,
        setEntries,
        setComments,
        setQuoteRequested,
        setUserAssigned,
        setOpenHistory,
        save,
        assign,
        send
      }
    })

const CrossQuoteEntry = types
    .model("CrossQuoteEntry", {
      quantity1: '',
      quantity2: '',
      quantity3: '',
      price1: types.maybe(types.string),
      price2: types.maybe(types.string),
      price3: types.maybe(types.string),
      partNumberToCross: '',
      fascompPartNumber: '',
      platingPrice1: types.maybe(types.string),
      platingPrice2: types.maybe(types.string),
      platingPrice3: types.maybe(types.string),
      delivery1: types.maybe(types.string),
      delivery2: types.maybe(types.string),
      delivery3: types.maybe(types.string),
      adminComments: types.optional(types.string, ''),
    })
    .views(self => ({

    }))
    .actions(self => ({
      setPartNumberToCross(partNumber) {
        self.partNumberToCross = partNumber
      },
      setFascompPartNumber(partNumber) {
        self.fascompPartNumber = partNumber
      },
      setQuantity1(quantity) {
        self.quantity1 = quantity
      },
      setQuantity2(quantity) {
        self.quantity2 = quantity
      },
      setQuantity3(quantity) {
        self.quantity3 = quantity
      },
      setPrice1(value) {
        self.price1 = value
      },
      setPrice2(value) {
        self.price2 = value
      },
      setPrice3(value) {
        self.price3 = value
      },
      setPlatingPrice1(value) {
        self.platingPrice1 = value
      },
      setPlatingPrice2(value) {
        self.platingPrice2 = value
      },
      setPlatingPrice3(value) {
        self.platingPrice3 = value
      },
      setDelivery1(value) {
        self.delivery1 = value
      },
      setDelivery2(value) {
        self.delivery2 = value
      },
      setDelivery3(value) {
        self.delivery3 = value
      },
      setAdminComments(value) {
        self.adminComments = value
      }
    }))


export const CrossQuote = types
    .model("CrossQuote", {
      id: types.identifier(),
      company: types.optional(types.string, ''),
      firstName: types.optional(types.string, ''),
      lastName: types.optional(types.string, ''),
      phone: types.optional(types.string, ''),
      email: types.optional(types.string, ''),
      quoteType: types.optional(types.string, ''),
      salesRepEmail: types.optional(types.string, ''),
      comments: types.optional(types.string, ''),
      entries: types.array(CrossQuoteEntry),
      quoteRequested: types.optional(types.boolean, false),
      userAssigned: types.maybe(UserEvent),
      openHistory: types.optional(types.array(UserEvent), []),
      dateCreated: types.optional(types.string, ''),
    })
    .views(self => ({
      get shop() {
        return getParent(getParent(self));
      },
      get parsedDateCreated() {
        return new Date(Date.parse(self.dateCreated));
      },
      get quoted() {
        let ret = true;
        self.entries.forEach(entry => {

          if (entry && (!entry.fascompPartNumber || entry.fascompPartNumber < 1))
            ret = false;

          if (entry && parseInt(entry.quantity1) > 0) {
            if (!entry.price1 || entry.price1.length < 1 || isNaN(entry.price1))
              ret = false;
          }
          if (entry && parseInt(entry.quantity2) > 0) {
            if (!entry.price2 || entry.price2.length < 1 || isNaN(entry.price2))
              ret = false;
          }
          if (entry && parseInt(entry.quantity3) > 0) {
            if (!entry.price3 || entry.price3.length < 1 || isNaN(entry.price3))
              ret = false;
          }
        });

        return ret;
      },      
      get assigned() {
        return self.userAssigned ? true : false;
      }
    }))
    .actions(self => {
      const setCompany = value => {
        self.company = value
      };

      const setFirstName = value => {
        self.firstName = value
      };

      const setLastName = value => {
        self.lastName = value
      };

      const setPhone = value => {
        self.phone = value
      };

      const setEmail = value => {
        self.email = value
      };

      const setQuoteType = value => {
        self.quoteType = value
      };

      const setSalesRepEmail = value => {
        self.salesRepEmail = value
      };

      const setEntries = value => {
        self.entries = value
      };

      const setComments = value => {
        self.comments = value
      };

      const setQuoteRequested = value => {
        self.quoteRequested = value
      };

      const setUserAssigned = value => {
        self.userAssigned = value
      };

      const setOpenHistory = value => {
        self.openHistory = value
      };

      const save = flow(function* save() {

        const response = yield fetch('/api/crossrfq/update', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(getSnapshot(self)),
          headers:{
            'Content-Type': 'application/json'
          }
        });

        const json = yield response.json();

        if (!response.ok) {
          if (response.status && (response.status == 401 || response.status == 403)) {
            self.shop.auth.setAuthRequested(true, () => {
                self.save();
            });
          }
          else {
            throw Object.assign(new AppError, json.errors[0]);
          }
        }

        return json;
      
      });

      const assign = flow(function* assign(userId) {

        const response = yield fetch('/api/crossrfq/assign', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify({
            id: self.id,
            userId
          }),
          headers:{
            'Content-Type': 'application/json'
          }
        });

        const json = yield response.json();

        if (!response.ok) {
          if (response.status && (response.status == 401 || response.status == 403)) {
            self.shop.auth.setAuthRequested(true, () => {
                self.assign(userId);
            });
          }
          else {
            throw Object.assign(new AppError, json.errors[0]);
          }
        }

        return json;
      
      });

      const send = flow(function* send() {

        const response = yield fetch('/api/crossrfq/send', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify({
            id: self.id
          }),
          headers:{
            'Content-Type': 'application/json'
          }
        });

        const json = yield response.json();

        if (!response.ok) {
          if (response.status && (response.status == 401 || response.status == 403)) {
            self.shop.auth.setAuthRequested(true, () => {
                self.send();
            });
          }
          else {
            throw Object.assign(new AppError, json.errors[0]);
          }
        }

        return json;
      
      });

      return {
        setCompany,
        setFirstName,
        setLastName,
        setEmail,
        setQuoteType,
        setSalesRepEmail,
        setPhone,
        setEntries,
        setComments,
        setQuoteRequested,
        setUserAssigned,
        setOpenHistory,
        save,
        assign,
        send
      }
      
    });

export const QuoteStore = types
  .model("QuoteStore", {
    isLoading: true,
    rfqQuotes: types.map(Quote),
    crossQuotes: types.map(CrossQuote)
  })
  .views(self => ({
    get shop() {
      return getParent(self)
    }
  }))
  .actions(self => {
    
    function afterAttach() {
    }

    function markLoading(loading) {
      self.isLoading = loading
    }

    function updateQuotes(json) {
      json.quotes.forEach(quote => {
        self.rfqQuotes.put(quote)
      });

      json.crossQuotes.forEach(crossQuote => {
        self.crossQuotes.put(crossQuote)
      })
    }

    function putRFQQuote(quote) {
      self.rfqQuotes.put(quote);
    }

    function putCrossQuote(quote) {
      self.crossQuotes.put(quote);
    }

    const _loadQuotes = flow(function* loadQuotes() {
      try {
        const json = yield self.shop.fetch("/api/quotes");

        if (json && json.status && (json.status == 401 || json.status == 403)) {
          self.shop.auth.setAuthRequested(true, () => {
              self.loadQuotes();
          });
        }
          
        updateQuotes(json)
        markLoading(false)
      } catch (err) {
        console.error("Failed to load quotes ", err)
      }
    })

    function loadQuotes() {
      return _loadQuotes();
    }


    function clear() {
      self.entries = [{}]
    }

    function readFromLocalStorage() {
      const quotesData = window.localStorage.getItem("quotes")
      if (quotesData) applySnapshot(self, JSON.parse(quotesData))
    }

    return {
      afterAttach,
      clear,
      readFromLocalStorage,
      loadQuotes,
      markLoading,
      updateQuotes,
      putRFQQuote,
      putCrossQuote
    }
  })