import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Divider,
  Grid,
  Image,
  Segment,
  Card,
  Header,
  Icon,
  Label,
  Flag,
  Message
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import USAMap from "react-usa-map";
import _ from 'underscore'
import repData from '../modules/repData'



const noRepData = {
    company: 'Fascomp Electronic Hardware',
    contact: 'Contact Fascomp for information on local reps in this area',
    street: '',
    city: '',
    state: '',
    zip: '',
    phone: '(407) 226-2112',
    fax: '(407) 226-3370',
    email: 'sales@fascomp.com',
    // stateHeadings: { CA: 'Southern California'},
    area: null,
    location: { lat: 32.4985901, lon: -97.2745976 }
  }

const AnyReactComponent = ({ text }) => <div>{text}</div>;


const RepCard = ({ card, filter }) => {

  return (
    <Card>
      <Card.Content>
        <Card.Header>
          {card.company}
        </Card.Header>
        <Card.Description>
          {card.contact && <div>{card.contact}</div>}
          {card.street && <div>{card.street}</div>}
          {(card.city || card.state || card.zip) && <div>{card.city && <span>{card.city},</span>} {card.state && <span>{card.state}</span>} {card.zip && <span>{card.zip}</span>}</div>}
          {card.phone && <div>tel: {card.phone}</div>}
          {card.fax && <div>fax: {card.fax}</div>}
          {card.mobile && <div>cell: {card.mobile}</div>}
          {card.email && <div><a href={'mailto:' + card.email}>{card.email}</a></div>}
          {card.website && <div><a href={'http://' + card.website} target="_blank">{card.website}</a></div>}
          {card.contacts && <div>
            {card.contacts.map((contact, idx) => (
              <div key={'contacts_' + idx}>{contact.name} - <a href={'mailto:' + contact.email}>{contact.email}</a> - {contact.phone}</div>
            ))}
          </div>}
          {card.area && card.company !== 'Fascomp Electronic Hardware' && <div>Area:&nbsp;
            {card.area.map((area, idx) => (
              <span key={'area_' + idx}>{area}{idx < card.area.length - 1 ? ', ' : ''}</span>
            ))}
          </div>}
        </Card.Description>
      </Card.Content>
      {card.stateHeadings && card.stateHeadings[filter] && <Card.Content extra>{card.stateHeadings[filter]}</Card.Content>}
    </Card>
  )
}

const RepCards = ({cards, filter}) => (
    cards.map((card, idx) => (
      <RepCard card={card} filter={filter} key={'repCard_' + idx} />
)))

class PageLocalReps extends Component {

  state = {
    filter: null
  }

  componentDidMount(){
    document.title = 'Sales Representatives - Fascomp Electronic Hardware'
  }

  handleMapClick = (event) => {
    const { filter } = this.state;

    console.log('EVENT TARGET:', event.target);

    //const name = event.target.dataset ? event.target.dataset.name : event.target.getAttribute('name');
    const name = event.target.getAttribute('data-name');

    if (filter == name)
      this.setState({ filter: null });
    else
      this.setState({ filter: name})
  };

  handleCountryButtonClick = (country) => {
    const { filter } = this.state;

    if (filter == country)
      this.setState({ filter: null });
    else
      this.setState({ filter: country})
  };

  get mapConfig() {

    const { filter } = this.state;
    const config = {};

    if (filter)
      config[filter] = { fill: "#3d1371" };

    return config;
  }

  get repData() {

    const { filter } = this.state;
    let filteredReps;

    if (filter)
    {
      filteredReps = repData.filter(rep =>  rep.area.indexOf(filter) > -1);
      if (filteredReps.length == 0)
        filteredReps.push(noRepData);

      return filteredReps;
    }

    return repData;
  }



  render() {
    const { filter } = this.state;

    console.log('FILTER RENDER', filter);

    return (
      <div className="pagelocalreps">
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='user' />
            <Header.Content>Sales Representatives</Header.Content>
          </Header>
          <Message
            icon='info circle'
            content='Select a state for your local sales representative'
          />
          <Segment textAlign='center' className="mapsegment">
            <div>
               <USAMap onClick={ this.handleMapClick } customize={ this.mapConfig } width="100%" height="" />
            </div>
            <div>
              <Label as='a' color={filter == 'Mexico' ? "violet" : ''} onClick={ () => this.handleCountryButtonClick('Mexico') }>
                <Flag name="mexico" /> Mexico
              </Label>
              <Label as='a' color={filter == 'Canada' ? "violet" : ''} onClick={ () => this.handleCountryButtonClick('Canada') }>
                <Flag name="canada" /> Canada
              </Label>
            </div>
          </Segment>


          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={16}>
                <Card.Group centered={true} stackable={true}>
                  <RepCards filter={filter} cards={this.repData} />
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <ScrollToTopOnMount />
      </div>
    );
  }
}


export default PageLocalReps