import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Segment,
  Container,
  Grid,
  Header,
  List,
  Image
} from 'semantic-ui-react'


class Footer extends Component {

  render() {
    const { shop } = this.props
    const { openCatalogPage, openTechnicalPage, openCrossRefPage, openLocalRepsPage, openCreatePrintPage, openContactUsPage } = shop.view;


    return (
      <Segment inverted vertical style={{ padding: '5em 0em' }} className="footer">
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>

              <Grid.Column width={3}>
                <Header inverted as='h4' content='Products' />
                <List link inverted>
                  <List.Item as='a' onClick={openCatalogPage}>Catalog / RFQ</List.Item>
                  <List.Item as='a' onClick={openCrossRefPage}>Cross-Ref / RFQ</List.Item>
                  <List.Item as='a' onClick={openTechnicalPage}>Technical Info</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='About' />
                <List link inverted>
                  <List.Item as='a' onClick={openContactUsPage}>Contact Us</List.Item>
                  <List.Item as='a' onClick={openLocalRepsPage}>Sales Representatives</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as='h4' inverted>Fascomp</Header>
                <p>Fine Quality Electronic Hardware</p>
                <p>Copyright © 2018</p>
              </Grid.Column>
              <Grid.Column width={2}>
                <Image
                  size='small'
                  src='/images/ISO.png'
                  alt='ISO Certified Company'
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
  }
}


export default inject("shop")(observer(Footer))
