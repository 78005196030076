import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import {
  Container,
  Table,
  Icon,
  Header
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PagePlating = () => {

  useEffect(() => {
    document.title = 'Plating Specifications - Fascomp Electronic Hardware';
  }, []);

  return (
  <div>
    <Container>
      <Header as='h2' dividing textAlign='center'>
        <Icon name='info circle' />
        <Header.Content>Plating Specifications</Header.Content>
      </Header>
      <Table unstackable columns={3}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="3">Aluminum</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Code</Table.HeaderCell>
            <Table.HeaderCell>Finish</Table.HeaderCell>
            <Table.HeaderCell>Specification</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>6</Table.Cell>
            <Table.Cell>Iridite (Gold)</Table.Cell>
            <Table.Cell>MIL-DTL-5541F Class 1A (Yellow Dull)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>7</Table.Cell>
            <Table.Cell>Iridite (Clear)</Table.Cell>
            <Table.Cell>MIL-DTL-5541F Class 3 (Clear Dull)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>9</Table.Cell>
            <Table.Cell>Alodine 1200</Table.Cell>
            <Table.Cell>MIL-DTL-5541F Class 1A Type I (Yellow Dull)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>21</Table.Cell>
            <Table.Cell>Dark Grey Anodize</Table.Cell>
            <Table.Cell>MIL-A-8625 Type II, Class 2 (Matte/Dull)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>22</Table.Cell>
            <Table.Cell>Clear Anodize</Table.Cell>
            <Table.Cell>MIL-A-8625 Type II, Class 1  (Matte/Dull)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>23</Table.Cell>
            <Table.Cell>Yellow/Green Anodize</Table.Cell>
            <Table.Cell>MIL-A-8625 Type II, Class 2  (Matte/Dull)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>24</Table.Cell>
            <Table.Cell>Black Anodize</Table.Cell>
            <Table.Cell>MIL-A-8625 Type II, Class 2  (Matte/Dull)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>34</Table.Cell>
            <Table.Cell>Clear Anodize</Table.Cell>
            <Table.Cell>MIL-A-8625 Type II, Class 1  (Glossy)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>35</Table.Cell>
            <Table.Cell>Black Anodize</Table.Cell>
            <Table.Cell>MIL-A-8625 Type II, Class 2  (Glossy)</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table unstackable columns={3}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="3">Stainless Steel</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Order Code</Table.HeaderCell>
            <Table.HeaderCell>Finish</Table.HeaderCell>
            <Table.HeaderCell>Specification</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>10</Table.Cell>
            <Table.Cell>Bright Chrome</Table.Cell>
            <Table.Cell>QQ-C-320 Type I, Class 1</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>20</Table.Cell>
            <Table.Cell>Passivation</Table.Cell>
            <Table.Cell>MIL-DTL-14072F, E300</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>26</Table.Cell>
            <Table.Cell>Black Oxide</Table.Cell>
            <Table.Cell>MIL-C-13924C (Class 4 Stainless)  (Class 1 Steel)</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table unstackable columns={3}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="3">Brass and Steel</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Order Code</Table.HeaderCell>
            <Table.HeaderCell>Finish</Table.HeaderCell>
            <Table.HeaderCell>Specification</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>Cad Clear (Commercial)</Table.Cell>
            <Table.Cell>QQ-P-416F Type I, Class 3 (.0002 min. thick)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>2</Table.Cell>
            <Table.Cell>Cad Clear</Table.Cell>
            <Table.Cell>QQ-P-416F Type I, Class 2 (.0003 min. thick)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3</Table.Cell>
            <Table.Cell>Cad Yellow</Table.Cell>
            <Table.Cell>QQ-P-416F Type II, Class 2 (.0003 min. thick)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5</Table.Cell>
            <Table.Cell>Nickel Plate</Table.Cell>
            <Table.Cell>QQ-N-290C Class I, Grade G (.0002 min thick) (Bright)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>10</Table.Cell>
            <Table.Cell>Bright Chrome</Table.Cell>
            <Table.Cell>QQ-C-320 Type I, Class 1</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>12</Table.Cell>
            <Table.Cell>Zinc Clear</Table.Cell>
            <Table.Cell>ASTM-B-633 Type III, Class 3 (.0002 min thick) Apperance Silver/Possible Blue Tint</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>13</Table.Cell>
            <Table.Cell>Bright Dip</Table.Cell>
            <Table.Cell>Brass only</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>14</Table.Cell>
            <Table.Cell>Electro Tin Over Copper Flash</Table.Cell>
            <Table.Cell>ASTM B545 Type I, Class A, B or C (Bright)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>15</Table.Cell>
            <Table.Cell>Hot Tin Dip</Table.Cell>
            <Table.Cell>MIL-T-10727C Type II</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>16</Table.Cell>
            <Table.Cell>Electro Tin</Table.Cell>
            <Table.Cell>MIL-T-10727C Type I</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>25</Table.Cell>
            <Table.Cell>Black Oxide</Table.Cell>
            <Table.Cell>MIL-F-495E (Brass)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>26</Table.Cell>
            <Table.Cell>Black Oxide</Table.Cell>
            <Table.Cell>MIL-C-13924C (Class 4 Stainless)  (Class 1 Steel)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>27</Table.Cell>
            <Table.Cell>Electro Tin-Lead (60/40)</Table.Cell>
            <Table.Cell>MIL-F-14072D Finish M230</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>28</Table.Cell>
            <Table.Cell>Zinc Yellow</Table.Cell>
            <Table.Cell>ASTM-B-633</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>37</Table.Cell>
            <Table.Cell>Ductile Nickel</Table.Cell>
            <Table.Cell>Dull/Low Luster</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>42</Table.Cell>
            <Table.Cell>Zinc Black</Table.Cell>
            <Table.Cell>ASTM-B-633 Type II, Class 3 (.0002 min thick) Dark Black</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>43</Table.Cell>
            <Table.Cell>Zinc Blue</Table.Cell>
            <Table.Cell>ASTM-B-633 Type III, Class 3 (.0002 min thick) Apperance Silver/Assured of Blue Tint</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Container>
    <ScrollToTopOnMount />
  </div>
  )
}

export default PagePlating