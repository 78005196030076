import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import _ from 'underscore'
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
    Form,
Search
} from 'semantic-ui-react'
import './MobileContainer.css'
import HomepageHeading from './HomepageHeading'
import Footer from './Footer'

const initialState = {
    isLoading: false,
    results: [],
    value: ''
  }

class MobileContainer extends Component {
  state = initialState

  handlePusherClick = () => {
    const { sidebarOpened } = this.state

    if (sidebarOpened) this.setState({ sidebarOpened: false })
  }

  handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened })

  handleSearchChange = (e, { value }) => {
    const { searchPartGroupsByPartNumber } = this.props.shop.partGroupStore

    this.setState({ isLoading: true, value });
    const searchResults = [];

    searchPartGroupsByPartNumber(value).then((partsGroupsFound) => {

      partsGroupsFound.forEach((partGroup) => {
        searchResults.push({
          title: partGroup[1].name,
          image: "/images/partGroups/" + partGroup[1].imgSrc,
          urlname: partGroup[1].urlName
        });
      })

      this.setState({
        isLoading: false,
        value,
        results: searchResults
      })

    });
  }

  handleResultSelect = (e, { result }) => {
    const { openPartGroupPageByUrlName } = this.props.shop.view;
    const { shop } = this.props;
    const { value } = this.state;
    shop.setLastSelectedSearch('');
    shop.setLastSelectedSearch(value);
    openPartGroupPageByUrlName(result.urlname);
  }

  render() {
    const { children, shop } = this.props
    const { page, openHomePage, openCatalogPage, openTechnicalPage, openCrossRefPage, openLocalRepsPage, openVideoResourcesPage, openContactUsPage, openRFQPage } = shop.view;
    const { isLoading, value, results, sidebarOpened } = this.state

    return (
      <Responsive {...Responsive.onlyMobile} className='MobileContainer'>
        <Menu fixed='top' inverted pointing secondary size='large' className='MobileMenu'>
          <Menu.Item onClick={this.handleToggle}>
            <Icon name='sidebar' />
          </Menu.Item>

          <Menu.Item position='right' className='partSearch'>
            <Search inverted  placeholder='Part Search...'
              loading={isLoading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={_.debounce(this.handleSearchChange, 500, {
                leading: true,
              })}
              results={results}
              value={value}
              {...this.props}/>
          </Menu.Item>
        </Menu>

        <Menu fixed='left' inverted secondary vertical size='large' className={'MobileMenuSide' + (sidebarOpened ? '' : ' closed')}>
          <Menu.Item as='a' onClick={() => { openHomePage(); this.handlePusherClick() }} active={page == 'home'}>Home</Menu.Item>
          <Menu.Item as='a' onClick={() => { openCatalogPage(); this.handlePusherClick() }} active={page == 'catalog'}>Catalog</Menu.Item>
          <Menu.Item as='a' onClick={() => { openRFQPage(); this.handlePusherClick() }} active={page == 'rfq'}>RFQ</Menu.Item>
          <Menu.Item as='a' onClick={() => { openTechnicalPage(); this.handlePusherClick() }} active={page == 'technical'}>Technical Info</Menu.Item>
          <Menu.Item as='a' onClick={() => { openVideoResourcesPage(); this.handlePusherClick() }} active={page == 'videoresources'}>Video Resources</Menu.Item>
          <Menu.Item as='a' onClick={() => { openCrossRefPage(); this.handlePusherClick() }} active={page == 'crossref'}>Cross-Ref / RFQ</Menu.Item>
          <Menu.Item as='a' onClick={() => { openLocalRepsPage(); this.handlePusherClick() }} active={page == 'localreps'}>Sales Representatives</Menu.Item>
          <Menu.Item as='a' onClick={() => { openContactUsPage(); this.handlePusherClick() }} active={page == 'contactus'}>Contact Us</Menu.Item>
        </Menu>
        <Sidebar.Pushable>
          <Sidebar as={Menu} animation='uncover' inverted vertical visible={sidebarOpened}>

          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened} onClick={this.handlePusherClick} style={{ minHeight: '100vh' }}>
            {(page === 'home') && <Segment inverted textAlign='center' className='MobileSegment' vertical>
              <HomepageHeading mobile />
            </Segment>}
            <div className='mobileChildren'>
              {children}
              <Footer />
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>

      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

export default inject("shop")(observer(MobileContainer))
