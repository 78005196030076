import { types, getParent } from "mobx-state-tree"

export const ViewStore = types
    .model({
        page: "home",
        selectedItemId: "",
        selectedProjectId: "",
        selectedPartGroupId: "",
        selectedPartGroupUrlName: "",
        selectedPartGroupPartNumber: "",
        selectedPartGroupPartNumberCross: "",
        selectedCategoryName: ""
    })
    .views(self => ({
        get shop() {
            return getParent(self)
        },
        get isLoading() {
            return self.shop.isLoading
        },
        get isFullWidth() {
            return self.page.startsWith('admin/');
        },
        get currentUrl() {
            switch (self.page) {
                case "home":
                    return "/"
                case "catalog":
                    return "/catalog"
                case "technical":
                    return "/technical"
                case "crossref":
                    return "/crossref"
                case "localreps":
                    return "/localreps"
                case "createprint":
                    return "/createprint"
                case "contactus":
                    return "/contactus"
                case "videoresources":
                    return "/videoresources"
                case "plating":
                    return "/plating"
                case "material":
                    return "/material"
                case "depthofthreadf":
                    return "/depthofthreadf"
                case "depthofthreadmf":
                    return "/depthofthreadmf"
                case "torquevalues":
                    return "/torquevalues"
                case "rohs":
                    return "/rohs"
                case "reach":
                    return "/reach"
                case "termsofsale":
                    return "/termsofsale"
                case "iso9001":
                    return "/iso9001"
                case "partgroup":
                    return "/partgroup/" + self.selectedPartGroupUrlName
                case "part":
                    return "/part/" + self.selectedPartGroupPartNumber
                case "partcross":
                    return "/partcross/" + self.selectedPartGroupPartNumberCross
                case "categorylanding":
                    return "/category/" + self.selectedCategoryName    
                case "items":
                    return "/items"
                case "item":
                    return "/item/" + self.selectedItemId
                case "cart":
                    return "/cart"
                case "rfq":
                    return "/rfq"
                case "admin/quotes":
                    return "/admin/quotes"
                case "admin/users":
                    return "/admin/users"    
                case "projects":
                    return "/projects"
                case "project":
                    return "/project/" + self.selectedProjectId
                default:
                    return "/404"
            }
        },
        get selectedItem() {
            return self.isLoading || !self.selectedItemId
                ? null
                : self.shop.items.get(self.selectedItemId)
        },
        get selectedProject() {
            return self.isLoading || !self.selectedProjectId
                ? null
                : self.shop.projects.get(self.selectedProjectId)
        },
        get selectedPartGroup() {
            if ((self.isLoading && self.isLoadingSingle) || (!self.selectedPartGroupUrlName && !self.selectedPartGroupPartNumber && !self.selectedPartGroupPartNumberCross))
                return null;
            
            if (!self.selectedPartGroupUrlName && !self.selectedPartGroupPartNumber)
                return self.shop.partGroupStore.partGroupByPartNumberCross(self.selectedPartGroupPartNumberCross);
            else if (!self.selectedPartGroupUrlName && !self.selectedPartGroupPartNumberCross)
                return self.shop.partGroupStore.partGroupByPartNumber(self.selectedPartGroupPartNumber);
            else
                return self.shop.partGroupStore.partGroupByUrlName(self.selectedPartGroupUrlName);
        },
    }))
    .actions(self => ({
        setSelectedPhotoId(id) {
            self.selectedPhotoId = id;
        },
        openHomePage(e) {
            if (e && e.preventDefault)
                e.preventDefault();

            self.shop.partGroupStore.loadPartGroups();
            self.page = "home"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openCatalogPage(e) {
            if (e && e.preventDefault)
                e.preventDefault();

            self.shop.partGroupStore.loadPartGroups();
            self.page = "catalog"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openTechnicalPage(e) {
            if (e && e.preventDefault)
                e.preventDefault();

            self.shop.partGroupStore.loadPartGroups();
            self.page = "technical"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openCrossRefPage(e) {
            if (e && e.preventDefault)
                e.preventDefault();

            self.shop.partGroupStore.loadPartGroups();
            self.page = "crossref"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openLocalRepsPage(e) {
            if (e && e.preventDefault)
                e.preventDefault();

            self.shop.partGroupStore.loadPartGroups();
            self.page = "localreps"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openCreatePrintPage(e) {
            if (e && e.preventDefault)
                e.preventDefault();

            self.shop.partGroupStore.loadPartGroups();
            self.page = "createprint"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openContactUsPage(e) {
            if (e && e.preventDefault)
                e.preventDefault();

            self.shop.partGroupStore.loadPartGroups();
            self.page = "contactus"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openVideoResourcesPage(e) {
            if (e && e.preventDefault)
                e.preventDefault();

            self.shop.partGroupStore.loadPartGroups();
            self.page = "videoresources"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openItemsPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "items"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openItemPage(item) {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "item"
            self.selectedItemId = item.id
            self.selectedProjectId = ""
        },
        openItemPageById(id) {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "item"
            self.selectedItemId = id
            self.selectedProjectId = ""
        },
        openCategoryPage(categoryName) {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "categorylanding"
            self.selectedItemId = ""
            self.selectedProjectId = ""
            self.selectedCategoryName = categoryName;
        },
        openProjectsPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "projects"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openProjectPage(project) {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "project"
            self.selectedItemId = ""
            self.selectedProjectId = project.id
        },
        openCartPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "cart"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openRFQPage(e) {
            if (e && e.preventDefault)
                e.preventDefault();
            
            self.shop.partGroupStore.loadPartGroups();
            self.page = "rfq"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openPlatingPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "plating"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openMaterialPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "material"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openDepthOfThreadFPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "depthofthreadf"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openDepthOfThreadMFPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "depthofthreadmf"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openTorqueValuesPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "torquevalues"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openROHSPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "rohs"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openREACHPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "reach"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openTermsOfSalePage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "termsofsale"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openPartGroupPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "partgroup"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        openPartGroupPageById(id) {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "partgroup"
            self.selectedItemId = ""
            self.selectedProjectId = ""
            self.selectedPartGroupId = id;
        },
        openPartGroupPageByUrlName(urlName) {
            self.shop.partGroupStore.loadPartGroupByUrl(urlName, true);
            self.page = "partgroup"
            self.selectedItemId = ""
            self.selectedProjectId = ""
            self.selectedPartGroupUrlName = urlName;
            self.selectedPartGroupPartNumber = "";
            self.selectedPartGroupPartNumberCross = "";
        },
        openPartGroupPageByPartNumber(partNumber) {
            self.shop.partGroupStore.loadPartGroupByPartNumber(partNumber, true);
            self.page = "part"
            self.selectedItemId = ""
            self.selectedProjectId = ""
            self.selectedPartGroupUrlName = "";
            self.selectedPartGroupPartNumber = partNumber;
            self.selectedPartGroupPartNumberCross = "";
        },
        openPartGroupPageByPartNumberCross(partCross) {
            self.shop.partGroupStore.loadPartGroupByCrossPartNumber(partCross, true);
            self.page = "partcross"
            self.selectedItemId = ""
            self.selectedProjectId = ""
            self.selectedPartGroupUrlName = "";
            self.selectedPartGroupPartNumber = "";
            self.selectedPartGroupPartNumberCross = partCross;
        },

        openISO9001Page() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "iso9001"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },

        openAdminQuotesPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "admin/quotes"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },

        openAdminUsersPage() {
            self.shop.partGroupStore.loadPartGroups();
            self.page = "admin/users"
            self.selectedItemId = ""
            self.selectedProjectId = ""
        },
        
    }))