import React, { useEffect } from 'react'
import ProductCardLines, { cardData } from './ProductCardLines'
import {
  Header,
  Icon,
  Container
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PageCreatePrint = () => {
  
  useEffect(() => {
    document.title = 'Create Print - Fascomp Electronic Hardware';
  }, []);

  return (
    <div>
      <Container>
        <Header as='h2' dividing textAlign='center'>
          <Icon name='file alternate outline' />
          <Header.Content>Create Print</Header.Content>
        </Header>
        <ProductCardLines lines={cardData} showPrints={true} />
      </Container>
      <ScrollToTopOnMount />
    </div>
  )
}

export default PageCreatePrint