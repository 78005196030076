import { observer, inject } from "mobx-react"
import { clone, getSnapshot, applySnapshot } from "mobx-state-tree"
import "./PageAdmin.css"
import { values } from "mobx"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Menu,
  Header,
  Icon,
  Table,
  Label,
  Button,
  Modal,
  Image,
  Message,
  Form,
  Confirm
} from 'semantic-ui-react'
import moment from 'moment';
import MaskedInput from 'react-text-mask'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { User } from '../stores/UserStore'
import AppError from "../modules/apperror"

const passwordDefault = '[__UnChAnGeD__]';

class PageAdminUsers extends Component {

  

  state = {
    modalOpen: false,
    deleteConfirmOpen: false,
    userViewed: null,
    password: passwordDefault,
    passwordConfirm: passwordDefault,
    errorReturned: null,
    errorFields: [],
    page: 1,
    recordsPerPage: 50
  }



  setModalOpen(value) {
    this.setState({
      modalOpen: value,
      errorReturned: null,
      errorFields: []
    });
  }

  showUser(user) {
    let password = '';

    if (user.id)
      password = passwordDefault;

    this.setState({
      modalOpen: true,
      password: password,
      passwordConfirm: password,
      userViewed: clone(user)
    });
  }

  handleItemClick = (e, { name }) => {

  };

  setCurrentPage(page) {
    let newPage = page;

    if (newPage > this.maxPage)
        newPage = this.maxPage;

    if (newPage < 1)
        newPage = 1;

    this.setState({
      page: newPage
    });
  }

  decrementPage = () => {
    this.setCurrentPage(this.getCurrentPage() - 1);
  }

  incrementPage = () => {
    this.setCurrentPage(this.getCurrentPage() + 1);
  }

  getMaxPage() {
    const { recordsPerPage } = this.state;
    const { shop } = this.props;
    return Math.ceil(shop.user.users.size / recordsPerPage);
  }

  getCurrentPage(tab) {
    const { page } = this.state;
    return page;
  }

  getUsers() {
    const { shop } = this.props;
    const { recordsPerPage } = this.state;
    const users = [...shop.user.users.values()];
    let minRow = ((this.getCurrentPage() - 1) * recordsPerPage) - 1;

    if (minRow < 0)
      minRow = 0;

    if (minRow > users.length - 1)
      minRow = 0;

    let maxRow = minRow + recordsPerPage;

    if (maxRow > users.length)
      maxRow = users.length;

    return users.slice(minRow, maxRow);
  }

  get maxPage() {
    return this.getMaxPage();
  }

  handlePageChange = (e, data) => {
    this.setCurrentPage(parseInt(data.children));
  };

  handleUsernameUpdate = e => {
    const { userViewed } = this.state;

    if (e.target.value) {
      userViewed.setUsername(e.target.value);
      this.setState({
        userViewed: userViewed
      })
    }
  }


  handleCompanyUpdate = e => {
    const { userViewed } = this.state;

    if (e.target.value) {
      userViewed.setCompany(e.target.value);
      this.setState({
        userViewed: userViewed
      })
    }
  }

  handleFirstNameUpdate = e => {
    const { userViewed } = this.state;

    if (e.target.value) {
      userViewed.setFirstName(e.target.value);
      this.setState({
        userViewed: userViewed
      })
    }
  }

  handleLastNameUpdate = e => {
    const { userViewed } = this.state;

    if (e.target.value) {
      userViewed.setLastName(e.target.value);
      this.setState({
        userViewed: userViewed
      })
    }
  }

  handlePhoneUpdate = e => {
    const { userViewed } = this.state;

    if (e.target.value) {
      userViewed.setPhone(e.target.value);
      this.setState({
        userViewed: userViewed
      })
    }
  }

  handleEmailUpdate = e => {
    const { userViewed } = this.state;

    if (e.target.value) {
      userViewed.setEmail(e.target.value);
      this.setState({
        userViewed: userViewed
      })
    }
  }

  handleEmailCCUpdate = e => {
    const { userViewed } = this.state;

    if (e.target.value) {
      userViewed.setEmailCC(e.target.value);
      this.setState({
        userViewed: userViewed
      })
    }
  }

  handleRoleUpdate = (e, obj) => {
    const { userViewed } = this.state;

    console.log(e, obj);
    if (obj.value) {
      userViewed.setRoles(obj.value.split(','));
      this.setState({
        userViewed: userViewed
      })
    }
  }

  handlePasswordUpdate = e => {
    if (e.target.value) {
      this.setState({
        password: e.target.value
      })
    }
  }

  handlePasswordConfirmUpdate = e => {
    if (e.target.value) {
      this.setState({
        passwordConfirm: e.target.value
      })
    }
  }

  validateInput = async () => {
    const { userViewed, password, passwordConfirm } = this.state
    const errorReturned = [];
    const errorFields = [];

    if (!userViewed.username || userViewed.username.length < 1) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a username<br /></span>)
    }

    

    if (!password || password.length < 1) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a password<br /></span>)
    }
    else if (password && password.length < 6) {
      errorReturned.push(<span key={'err' + errorReturned.length}>Password must be at least characters<br /></span>)
    }
    else if (password !== passwordConfirm) {
      errorReturned.push(<span key={'err' + errorReturned.length}>Password and password confirm must match<br /></span>)
    }

    if (!userViewed.company || userViewed.company.length < 1) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a company name<br /></span>)
    }

    if (!userViewed.firstName || userViewed.firstName.length < 1) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a first name<br /></span>)
    }

    if (!userViewed.lastName || userViewed.lastName.length < 1) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a last name<br /></span>)
    }

    if (!userViewed.email || userViewed.email.length < 1
      || !userViewed.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a valid email address<br /></span>)
    }

    if (userViewed.emailCC && userViewed.emailCC.length > 0) {
       for (const email of userViewed.emailCC.split(',')) {
          if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            errorReturned.push(<span key={'err' + errorReturned.length}>Email CC addresses are invalid<br /></span>)
            break;
          }
       }
    }

    if (!userViewed.phone || userViewed.phone.replace('_', '').length < 14) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a valid phone number<br /></span>)
    }


    this.setState({
      errorReturned: errorReturned,
      errorFields: errorFields
    });
    
    return errorReturned.length === 0;
  }

  handleUserUpdate = async () => {
    const { userViewed, password } = this.state;
    const { shop } = this.props;
    const errorReturned = [];

    if (userViewed) {
      const isValid = await this.validateInput();

      if (isValid) {
        userViewed.setPassword(password);

        try {
          await userViewed.save();
          await shop.user.loadUsers();

          this.setState({
            modalOpen: false,
            userViewed: null,
          });
        }
        catch(err) {
          if (err instanceof AppError) {
            errorReturned.push(err.message);
          }
          else {
            errorReturned.push(err.toString());
          }

          this.setState({
            errorReturned: errorReturned
          });
        }
      }
    }
  }

  handleUserDeleteConfirm = () => {
    this.setState({
      deleteConfirmOpen: true
    });
  }

  handleUserDeleteConfirmCancel = () => {
    this.setState({
      deleteConfirmOpen: false
    });
  }

  handleUserDelete = async () => {
    const { userViewed } = this.state;
    const { shop } = this.props;
    const errorReturned = [];

    if (userViewed && userViewed.id) {

      try {
        await shop.user.deleteUser(userViewed.id);

        this.setState({
          modalOpen: false,
          deleteConfirmOpen: false,
          userViewed: null,
        });
      }
      catch(err) {
        if (err instanceof AppError) {
          errorReturned.push(err.message);
        }
        else {
          errorReturned.push(err.toString());
        }

        this.setState({
          errorReturned: errorReturned
        });
      }
    }
  }

  componentDidMount() {
    const { shop } = this.props;
    shop.user.loadUsers();
  }

  renderTabContent(users) {

    const { shop } = this.props;
    const pagesArr =  [];
    for (let i = 1; i <= this.getMaxPage(); i++) {
      pagesArr.push(i);
    }

    return(
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Contact</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Date Created</Table.HeaderCell>
            <Table.HeaderCell collapsing></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!shop.user.isLoading && users.map((user) => (
              <Table.Row key={user.id}>
                <Table.Cell>{user.username}</Table.Cell>
                <Table.Cell>{user.company}</Table.Cell>
                <Table.Cell>{user.firstName} {user.lastName}</Table.Cell>
                <Table.Cell>{user.phone}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{moment(user.dateCreated).format('MM/DD/YYYY')}</Table.Cell>
                <Table.Cell collapsing><Button content='Details' icon='file alternate' labelPosition='left' onClick={() => { this.showUser(user)} } /></Table.Cell>
              </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='7'>
            <Button className="addUser" content='Add User' icon='file alternate' labelPosition='left' onClick={() => { this.showUser(User.create({}))} } />
              <Menu floated='right' pagination>
                <Menu.Item key={'prev'} as='a' onClick={this.decrementPage} disabled={this.getCurrentPage() == 1} icon>
                  <Icon name='chevron left' />
                </Menu.Item>
                {pagesArr.map((page) => (
                    <Menu.Item key={'page' + page} as='a' onClick={this.handlePageChange} active={this.getCurrentPage() == page}>{page}</Menu.Item>
                ))}
                <Menu.Item key={'next'} as='a' onClick={this.incrementPage} disabled={this.getCurrentPage() == this.getMaxPage()} icon>
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }


  render() {
    const { shop } = this.props;
    const { modalOpen, userViewed, errorReturned, password, passwordConfirm, deleteConfirmOpen } = this.state
    const users = this.getUsers();
    const roleOptions = [
      {
        key: 'Admin',
        text: 'Admin',
        value: 'View Quotes,Manage Quotes,Manage Users'
      },
      {
        key: 'Distributor',
        text: 'Distributor',
        value: 'Distributor'
      }
    ];

    if (userViewed) {
      console.log(userViewed.roles.join(','));
    }

    return(
      <div className='pageAdminUsers'>
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='user' />
            <Header.Content>Users</Header.Content>
          </Header>
          { this.renderTabContent(users) }
        </Container>
        <Confirm
          open={deleteConfirmOpen}
          header='Delete User'
          content='Are you sure you wish to delete this user?'
          onConfirm={this.handleUserDelete}
          onCancel={this.handleUserDeleteConfirmCancel}
        />
        <Modal
            onClose={() => this.setModalOpen(false)}
            onOpen={() => this.setModalOpen(true)}
            open={modalOpen}
            className="userDetailsModal"
        >
          <Modal.Header>User Details</Modal.Header>
          <Modal.Content>
            {userViewed &&
            <Table celled striped>

              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>Username</Table.Cell>
                  <Table.Cell><Form.Input value={userViewed.username} onChange={this.handleUsernameUpdate} /></Table.Cell>
                </Table.Row>
                <Table.Row className='companyInput'>
                  <Table.Cell collapsing>Company</Table.Cell>
                  <Table.Cell><Form.Input value={userViewed.company} onChange={this.handleCompanyUpdate} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>First Name</Table.Cell>
                  <Table.Cell><Form.Input value={userViewed.firstName} onChange={this.handleFirstNameUpdate} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Last Name</Table.Cell>
                  <Table.Cell><Form.Input value={userViewed.lastName} onChange={this.handleLastNameUpdate} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Password</Table.Cell>
                  <Table.Cell><Form.Input type='password' value={password} onChange={this.handlePasswordUpdate} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Password Confirm</Table.Cell>
                  <Table.Cell><Form.Input type='password' value={passwordConfirm} onChange={this.handlePasswordConfirmUpdate} /></Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell collapsing>Phone</Table.Cell>
                  <Table.Cell>
                    <Form.Input placeholder='(XXX) XXX-XXXX' width={8}>
                      <MaskedInput
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="(XXX) XXX-XXXX"
                        value={userViewed.phone}
                        onChange={this.handlePhoneUpdate}
                      />
                    </Form.Input>
                  </Table.Cell>
                </Table.Row>
                <Table.Row className='emailInput'>
                  <Table.Cell collapsing>Email</Table.Cell>
                  <Table.Cell><Form.Input value={userViewed.email} onChange={this.handleEmailUpdate} /></Table.Cell>
                </Table.Row>
                <Table.Row className='emailInput'>
                  <Table.Cell collapsing>Email CC</Table.Cell>
                  <Table.Cell><Form.Input value={userViewed.emailCC} onChange={this.handleEmailCCUpdate} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Role</Table.Cell>
                  <Table.Cell><Form.Dropdown selection options={roleOptions} value={userViewed.roles.join(',')} onChange={this.handleRoleUpdate} /></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            }
            {(errorReturned && errorReturned.length > 0) && <Message negative>
              <Message.Header>We're sorry, there was a problem with the user submission</Message.Header>
              <p>{errorReturned}</p>
            </Message>}
          </Modal.Content>
          <Modal.Actions>
            {(userViewed && userViewed.id && userViewed.id.length > 0) && 
            <Button className='modalbutton modalDelete' onClick={this.handleUserDeleteConfirm}>
              Delete
            </Button>}
            <Button className='modalbutton' onClick={this.handleUserUpdate}>
              {userViewed && userViewed.id ? 'Update' : 'Create'}
            </Button>
            <Button className='modalbutton' onClick={() => this.setModalOpen(false)}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <ScrollToTopOnMount />
      </div>
    )
  }
}

export default inject("shop")(observer(PageAdminUsers))