import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DesktopContainer from './DesktopContainer'
import MobileContainer from './MobileContainer'
import {
    Button,
    Modal,
    Form,
    Message
} from 'semantic-ui-react'


class ResponsiveContainer extends Component {

    propTypes = {
        children: PropTypes.node
    };

    render() {
        const { shop, children } = this.props;

        return (
            <div className={'ResponsiveContainer page' + shop.view.page }>
                <DesktopContainer>{children}</DesktopContainer>
                <MobileContainer>{children}</MobileContainer>
                <Modal open={shop.auth.authRequested}>
                    <Modal.Header>Authorization Required</Modal.Header>
                    <Modal.Content>
                        <Form>
                            {shop.auth.authFailed && <Message negative>
                                <Message.Header>Authentication failed</Message.Header>
                                <p>Please check your username and password and try again</p>
                            </Message>}
                            <Form.Field>
                                <label>Username</label>
                                <input placeholder='Username' onChange={(e) => shop.auth.setUsername(e.target.value)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Password</label>
                                <input placeholder='Password' type="password"  onChange={(e) => shop.auth.setPassword(e.target.value)} />
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='modalbutton' onClick={() => shop.auth.authenticate()}>
                            Submit
                        </Button>
                        <Button className='modalbutton' onClick={() => shop.auth.setAuthRequested(false)}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }

}

/*
const ResponsiveContainer = ({ children, shop }) => (
  <div className={'ResponsiveContainer page' + shop.view.page }>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
      <Modal open={shop.auth.authRequested}>
          <Modal.Header>Authorization Required</Modal.Header>
          <Modal.Content>
              <Form>
                  <Form.Field>
                      <label>Username</label>
                      <input placeholder='Username' />
                  </Form.Field>
                  <Form.Field>
                      <label>Password</label>
                      <input placeholder='Password' type="password" />
                  </Form.Field>
              </Form>
          </Modal.Content>
          <Modal.Actions>
              <Button className='modalbutton' onClick={() => shop.auth.setAuthRequested(false)}>
                  Submit
              </Button>
              <Button className='modalbutton' onClick={() => shop.auth.setAuthRequested(false)}>
                  Cancel
              </Button>
          </Modal.Actions>
      </Modal>
  </div>
)


ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}
 */
export default inject("shop")(observer(ResponsiveContainer))