import React, { useEffect } from 'react'
import {
  Container,
  Header,
  Card,
  Message,
  Icon,
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PageMaterial = () => { 

  useEffect(() => {
    document.title = 'Material Specifications - Fascomp Electronic Hardware';
  }, []);

  return (
  <div>

    <Container>
      <Header as='h2' dividing textAlign='center'>
        <Icon name='info circle' />
        <Header.Content>Material Specifications</Header.Content>
      </Header>

      <Card.Group centered="true" stackable="true">
        <Card>
          <Card.Content>
            <Card.Header>
              Aluminum
            </Card.Header>
            <Card.Description>
              <div>2011R Aluminum</div>
              <div>QQ-A-225/3D, QQ-A-225/6A, QQ-A-225-8B</div>
              <div>6061 T6 Aluminum</div>
              <div>QQ-A-225-8B</div>
            </Card.Description>
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              Brass
            </Card.Header>
            <Card.Description>
              <div>360 Half Hard Brass</div>
              <div>ASTM-B-16</div>
              <div>3604 Brass</div>
              <div>JIS H 3250</div>
            </Card.Description>
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>
              Steel
            </Card.Header>
            <Card.Description>
              <div>12L14 Steel</div>
              <div>ASTM-A-108-07</div>
              <div>1215 Steel</div>
              <div>ASTM-A-108-07</div>
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
      <Card.Group centered="true" stackable="true">
        <Card>
          <Card.Content>
            <Card.Header>
              Stainless Steel
            </Card.Header>
            <Card.Description>
              <div>303 Stainless Steel</div>
              <div>ASTM-A-581, A-582</div>
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Card.Header>
              Nylon
            </Card.Header>
            <Card.Description>
              <div>6/6 (UL 94V2)</div>
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>

      <Message floating>
        <Message.Header>Standard Tolerances</Message.Header>
        <Message.Header>Aluminum, Brass, Steel & Stainless Steel:</Message.Header>
        <ul>
          <li>Lengths up to and including 6′′ (inches) the tolerance is +/-.005</li>
          <li>Lengths over 6′′ (inches) the tolerance is +/-.010</li>
          <li>Lengths up to and including 99 mm (millimeters) the tolerance is +/-.13 mm</li>
          <li>Lengths from 100 mm to 150 mm the tolerance is +/-.20 mm</li>
          <li>Lengths over 150 mm the tolerance is +/-.25 mm</li>
          <li>Tolerances for the outside diameters are +/-.005.</li>
        </ul>
        <Message.Header>Nylon:</Message.Header>
        <ul>
          <li>For all lengths, the tolerance is +/-.010</li>
          <li>For the outside diameter, the tolerance is +/-.010</li>
        </ul>
      </Message>











    </Container>
    <ScrollToTopOnMount />
  </div>
  )
}

export default PageMaterial