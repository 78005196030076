import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { cardData, getPartGroups } from './ProductCardLines'
import {
  Container,
  Menu,
  Header,
  Icon
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import ProductCard from "./ProductCard"

class PageCategoryLanding extends Component {

  componentDidMount(){
    const { shop } = this.props;
    const { selectedCategoryName } = shop.view;
    const formattedCategoryName = selectedCategoryName.split('_').join(' ')
    document.title = formattedCategoryName + ' - Fascomp Electronic Hardware';
    const partGroupStandard = getPartGroups(cardData[0].products, formattedCategoryName);
    const partGroupMetric = getPartGroups(cardData[1].products, formattedCategoryName);
    console.log(partGroupStandard, partGroupMetric);
  }

  handleClickPartGroup = (e, id, urlName) => {
    const { openPartGroupPageByUrlName, openPartGroupPageById } = this.props.shop.view;
    if (id)
      openPartGroupPageById(id);
    else
      openPartGroupPageByUrlName(urlName);
  };

/*
  handleClickPartGroup = (e, id, urlName) => {
    const { openPartGroupPageByUrlName, openPartGroupPageById } = this.props.shop.view;
    if (id)
      openPartGroupPageById(id);
    else
      openPartGroupPageByUrlName(urlName);
  };
*/
  render() {
    const { shop } = this.props;
    const { selectedCategoryName } = shop.view;
    const formattedCategoryName = selectedCategoryName.split('_').join(' ')
    const partGroupStandard = getPartGroups(cardData[0].products, formattedCategoryName);
    const partGroupMetric = getPartGroups(cardData[1].products, formattedCategoryName);
    
    return(
      <div className="categoryLanding">
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='book' />
            <Header.Content>{ formattedCategoryName }</Header.Content>
          </Header>
          { partGroupStandard && <img className="categoryImage" src={partGroupStandard.image} /> }
          { partGroupStandard && <ProductCard card={partGroupStandard} namePrefix="Standard " showPrints={false} key={'StandardProductCard_'} onClickPartGroup={this.handleClickPartGroup} /> }
          { partGroupMetric && <ProductCard card={partGroupMetric} namePrefix="Metric " showPrints={false} key={'MetricProductCard_'} onClickPartGroup={this.handleClickPartGroup} /> }
        </Container>
        <ScrollToTopOnMount />
      </div>
    )
  }
}

export default inject("shop")(observer(PageCategoryLanding))