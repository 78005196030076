// 4.2.2.2 Ephemeral Animation State
import React, { Component } from "react"
import { observer } from "mobx-react"

class IsolatedComponent extends Component {

    constructor() {
        super()
        this.state = { margin : 0 }
    }

    render() {

        const { margin } = this.state
        return (
            <div>
                <h3>Isolated Component</h3>
                Margin: {margin}
                <button onClick={this.onAnimate}>Animate</button>
            </div>
        )
    }

    onAnimate = (e) => {

        const { setStatus } = this.props

        setStatus('Animating')
        let margin = 0
        let button = e.target
        let dir = 1
        let id = setInterval(() => {

            if (dir)
                margin++
            else
                margin--

            this.setState({ margin: margin})

            button.style.marginRight = margin + 'px'
            if (margin === 100)
                dir = 0
            else if (dir === 0 && margin === 0)
            {
                setStatus('Finished')
                clearInterval(id)
            }
        }, 10)

    }
}

export default observer(IsolatedComponent)