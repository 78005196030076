import { when, reaction } from "mobx"
import { types, getParent, getSnapshot, applySnapshot } from "mobx-state-tree"
import { PartSelected } from "./PartGroupStore"
import AppError from "../modules/apperror"
import * as Promise from "bluebird";

const CrossRefEntry = types
  .model("CrossRefEntry", {
    quantity1: 0,
    quantity2: 0,
    quantity3: 0,
    partNumberToCross: '',
    fascompPartNumber: ''
  })
  .views(self => ({
    get item() {
      return self.project.item
    }
  }))
  .actions(self => ({
    setPartNumberToCross(partNumber) {
      self.partNumberToCross = partNumber
    },
    setFascompPartNumber(partNumber) {
      self.fascompPartNumber = partNumber
    },
    setQuantity1(quantity) {
      self.quantity1 = quantity
    },
    setQuantity2(quantity) {
      self.quantity2 = quantity
    },
    setQuantity3(quantity) {
      self.quantity3 = quantity
    }
  }))

export const CrossRefStore = types
  .model("CrossRefStore", {
    company: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    comments: types.optional(types.string, ''),
    entries: types.array(CrossRefEntry),
    quoteRequested: types.optional(types.boolean, false),
  })
  .views(self => ({
    get shop() {
      return getParent(self)
    },
    get totalQuantity() {
      return self.entries.reduce((sum, e) => sum + e.quantity, 0)
    },

    get canRequestQuote() {

      return (
        self.firstName.length > 0 &&
        self.lastName.length > 0 &&
        self.phone.length > 0 &&
        self.email.length > 0 &&
        self.entries.length > 0 &&
        self.entries.every(entry => (entry.quantity1 > 0 || entry.quantity2 > 0 || entry.quantity3 > 0))
      )
    }
  }))
  .actions(self => ({
    afterAttach() {
      if (typeof window !== "undefined" && window.localStorage) {
        when(
          () => !self.shop.isLoading,
          () => {
            self.readFromLocalStorage()
            reaction(
              () => getSnapshot(self),
              json => {
                window.localStorage.setItem("crossref", JSON.stringify(json))
              }
            )
          }
        )
      }
    },
    addPart() {
      self.entries.push({})
    },
    removePart(index) {
      const filteredEntries = self.entries.filter((entry, idx) => idx !== index)
      self.entries.replace(filteredEntries);
    },
    setCompany(company) {
      self.company = company;
    },
    setFirstName(firstName) {
      self.firstName = firstName;
    },
    setLastName(lastName) {
      self.lastName = lastName;
    },
    setEmail(email) {
      self.email = email;
    },
    setPhone(phone) {
      self.phone = phone;
    },
    setComments(comments) {
      self.comments = comments;
    },
    requestQuote() {
      let response;

      return fetch('/api/crossrfq', {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(getSnapshot(self)), // data can be `string` or {object}!
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => {
        response = res;
        return res.json();
      })
      .then(resjson => {
        if (!response.ok) {
          throw Object.assign(new AppError, resjson)
        }
        self.clear();
        console.log('Success:', JSON.stringify(response))
        return response;
      });
    },
    clear() {
      self.entries = [{}]
    },
    readFromLocalStorage() {
      const crossrefData = window.localStorage.getItem("crossref")
      if (crossrefData) applySnapshot(self, JSON.parse(crossrefData))
    }
  }))