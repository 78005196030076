import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Form
} from 'semantic-ui-react'


const options = [
  { key: 'bl', text: 'Business Loan', value: 'business' },
  { key: 'hl', text: 'Home Loan', value: 'home' },
  { key: 'cl', text: 'Car Loan', value: 'auto' },
  { key: 'pl', text: 'Personal Loan', value: 'personal' },
  { key: 'dc', text: 'Debt Consolidation', value: 'debt' },
]


class LoanSelect extends Component {

  constructor(props) {
    super(props)

    this.state = {
    }
  }

  handleChangeLoanType = (e, { value }) => this.setState({ loanType: value })

  render() {
    const { value, incorporated, sales, salesAmount, cc, ccAmount, loans, loansAmount, creditScore } = this.state

    return (
      <Container text>
        <Form size="large">
          <Form.Select fluid label='Type of loan you’re looking for' options={options} placeholder='Loan Type' onChange={this.handleChangeLoanType} />
        </Form>
        <p>Funding for your business or startup is just a few clicks away!</p>
        <p>There are no wrong answers. We have lenders for ALL situations.</p> 
      </Container>
     )
  }
}



export default inject("shop")(observer(LoanSelect))