import { observer, inject } from "mobx-react"
import { clone, getSnapshot, applySnapshot } from "mobx-state-tree"
import "./PageAdmin.css"
import { values } from "mobx"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Menu,
  Header,
  Icon,
  Table,
  Label,
  Button,
  Modal,
  Image,
  Tab,
  Form,
  Message,
  TextArea
} from 'semantic-ui-react'
import moment from 'moment';
import AppError from "../modules/apperror"
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Quote, CrossQuote } from '../stores/QuoteStore'
import repData from '../modules/repData'

class PageAdminQuotes extends Component {

  state = {
    modalOpen: false,
    assignModalOpen: false,
    quoteModified: false,
    quoteViewed: null,
    errorReturned: null,
    assignErrorReturned: null,
    errorFields: [],
    rfqPage: 1,
    crossPage: 1,
    recordsPerPage: 50,
    activeTab: 'rfq',
  }

  setModalOpen(value) {
    this.setState({
      modalOpen: value
    });
  }

  setAssignModalOpen(value) {
    this.setState({
      assignModalOpen: value
    });
  }

  showQuote(quote) {
    this.setState({
      modalOpen: true,
      quoteModified: false,
      quoteViewed: clone(quote),
      errorReturned: null,
      errorFields: []
    });
  }

  showQuoteAssign = () => {
    const { quoteViewed } = this.state;

    if (!quoteViewed)
      return;

    this.setState({
      assignModalOpen: true,
    });  
  }

  handleItemClick = (e, { name }) => {

  };

  setCurrentPage(page) {
    const { activeTab } = this.state;
    this.setCurrentPageForTab(activeTab, page);
  }

  setCurrentPageForTab(tab, page) {
    let newPage = page;

    if (newPage > this.maxPage)
        newPage = this.maxPage;

    if (newPage < 1)
        newPage = 1;

    if (tab == 'rfq') {
      this.setState({
        rfqPage: newPage
      });
    }
    else {
      this.setState({
        crossPage: newPage
      });
    }
  }

  decrementPageForTab(tab) {
    this.setCurrentPageForTab(tab, this.getCurrentPageForTab(tab) - 1);
  }

  incrementPageForTab(tab) {
    this.setCurrentPageForTab(tab, this.getCurrentPageForTab(tab) + 1);
  }

  decrementPage = () => {
    const { activeTab } = this.state;
    this.decrementPageForTab(activeTab);
  }

  incrementPage = () => {
    const { activeTab } = this.state;
    this.incrementPageForTab(activeTab);
  }

  getMaxPageForTab(tab) {
    const { recordsPerPage } = this.state;
    const { shop } = this.props;
    return tab == 'rfq' ? Math.ceil(shop.quotes.rfqQuotes.size / recordsPerPage) : Math.ceil(shop.quotes.crossQuotes.size / recordsPerPage);
  }

  getCurrentPageForTab(tab) {
    const { rfqPage, crossPage } = this.state;
    return tab == 'rfq' ? rfqPage : crossPage;
  }

  getQuotesForTab(tab) {
    const { shop } = this.props;
    const { recordsPerPage } = this.state;
    const quotes = tab == 'rfq' ? [...shop.quotes.rfqQuotes.values()] : [...shop.quotes.crossQuotes.values()];
    let minRow = ((this.getCurrentPageForTab(tab) - 1) * recordsPerPage) - 1;

    if (minRow < 0)
      minRow = 0;

    if (minRow > quotes.length - 1)
      minRow = 0;

    let maxRow = minRow + recordsPerPage;

    if (maxRow > quotes.length - 1)
      maxRow = quotes.length - 1;

    return quotes.slice(minRow, maxRow);
  }

  get maxPage() {
    const { activeTab } = this.state;
    return this.getMaxPageForTab(activeTab);
  }

  handlePageChange = (e, data) => {
    this.setCurrentPage(parseInt(data.children));
  };

  handleTabChange = (e, data) => {
    this.setState({
      activeTab: data.panes[data.activeIndex].menuItem.toLowerCase()
    });
  };

  setQuoteModified = () => {
    const { quoteViewed } = this.state;
    this.setState({ quoteModified: true, quoteViewed });
  }

  handleFascompPartNumberUpdate = (e, entry) => {
    if (e.target.value) entry.setFascompPartNumber(e.target.value);
    this.setQuoteModified();
  }

  handleQuantity1Update = (e, entry) => {
    if (e.target.value) entry.setQuantity1(e.target.value);
    this.setQuoteModified();
  }

  handleQuantity2Update = (e, entry) => {
    if (e.target.value) entry.setQuantity2(e.target.value);
    this.setQuoteModified();
  }

  handleQuantity3Update = (e, entry) => {
    if (e.target.value) entry.setQuantity3(e.target.value);
    this.setQuoteModified();
  }

  handlePrice1Update = (e, entry) => {
    if (e.target.value) entry.setPrice1(e.target.value);
    this.setQuoteModified();
  }

  handlePrice2Update = (e, entry) => {
    if (e.target.value) entry.setPrice2(e.target.value);
    this.setQuoteModified();
  }

  handlePrice3Update = (e, entry) => {
    if (e.target.value) entry.setPrice3(e.target.value);
    this.setQuoteModified();
  }

  handlePlatingPrice1Update = (e, entry) => {
    if (e.target.value) entry.setPlatingPrice1(e.target.value);
    this.setQuoteModified();
  }

  handlePlatingPrice2Update = (e, entry) => {
    if (e.target.value) entry.setPlatingPrice2(e.target.value);
    this.setQuoteModified();
  }

  handlePlatingPrice3Update = (e, entry) => {
    if (e.target.value) entry.setPlatingPrice3(e.target.value);
    this.setQuoteModified();
  }

  handleDelivery1Update = (e, entry) => {
    if (e.target.value) entry.setDelivery1(e.target.value);
    this.setQuoteModified();
  }

  handleDelivery2Update = (e, entry) => {
    if (e.target.value) entry.setDelivery2(e.target.value);
    this.setQuoteModified();
  }

  handleDelivery3Update = (e, entry) => {
    if (e.target.value) entry.setDelivery3(e.target.value);
    this.setQuoteModified();
  }

  handleAdminCommentsUpdate = (e, entry) => {
    if (e.target.value) entry.setAdminComments(e.target.value);
    this.setQuoteModified();
  }

  handleQuoteTypeUpdate = (e, obj) => {
    const { quoteViewed } = this.state;

    if (obj.value) {
      quoteViewed.setQuoteType(obj.value);
      this.setQuoteModified();
    }
  }

  handleSalesRepUpdate = (e, obj) => {
    const { quoteViewed } = this.state;

    if (obj.value) {
      quoteViewed.setSalesRepEmail(obj.value);
      this.setQuoteModified();
    }
  }

  validateInput = async () => {
    const { quoteViewed } = this.state;
    const errorReturned = [];
    const errorFields = [];
    let quantityError = false;
    let priceError = false;

    if (!quoteViewed.company || quoteViewed.company.length < 1) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a company name<br /></span>)
    }

    if (!quoteViewed.firstName || quoteViewed.firstName.length < 1) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a first name<br /></span>)
    }

    if (!quoteViewed.lastName || quoteViewed.lastName.length < 1) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a last name<br /></span>)
    }

    if (!quoteViewed.email || quoteViewed.email.length < 1
      || !quoteViewed.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a valid email adddress<br /></span>)
    }

    if (!quoteViewed.phone || quoteViewed.phone.replace('_', '').length < 14) {
      errorReturned.push(<span key={'err' + errorReturned.length}>You must enter a valid phone number<br /></span>)
    }

    quoteViewed.entries.forEach(entry => {

      if (!quantityError && (isNaN(entry.quantity1) || isNaN(entry.quantity2) || isNaN(entry.quantity3))) {
        errorReturned.push(<span key={'err' + errorReturned.length}>All quantities entered must be valid numbers<br /></span>)
        quantityError = true;
      }

      if (!priceError && (isNaN(entry.price1) || isNaN(entry.price2) || isNaN(entry.price3) 
        || isNaN(entry.platingPrice1) || isNaN(entry.platingPrice2) || isNaN(entry.platingPrice3))) {
        errorReturned.push(<span key={'err' + errorReturned.length}>All prices entered must be valid numbers<br /></span>)
        priceError = true;
      }
    });


    this.setState({
      errorReturned: errorReturned,
      errorFields: errorFields
    });
    
    return errorReturned.length === 0;
  }

  handleQuoteAssign = async (user) => {
    const { quoteViewed } = this.state;
    const { shop } = this.props;
    const errorReturned = [];

    try {
      const response = await quoteViewed.assign(user.id);
      let quote;

      if (quoteViewed.entries && quoteViewed.entries.length > 0 && quoteViewed.entries[0].part) {
        quote = Quote.create(response);  
        await shop.quotes.putRFQQuote(quote);
      }
      else {
        quote = CrossQuote.create(response); 
        await shop.quotes.putCrossQuote(quote);
      }

      this.setState({
        assignModalOpen: false,
        quoteViewed: clone(quote)
      });
    }
    catch(err) {
      if (err instanceof AppError) {
        errorReturned.push(err.message);
      }
      else {
        errorReturned.push(err.toString());
      }

      this.setState({
        assignErrorReturned: errorReturned
      });
    }
  };

  handleQuoteUpdate = async () => {
    const { quoteViewed, password } = this.state;
    const { shop } = this.props;
    const errorReturned = [];

    if (quoteViewed) {
      const isValid = await this.validateInput();

      if (!isValid)
        return;

      try {
        await quoteViewed.save();

        if (quoteViewed.entries && quoteViewed.entries.length > 0 && quoteViewed.entries[0].part)
          await shop.quotes.putRFQQuote(quoteViewed);
        else
          await shop.quotes.putCrossQuote(quoteViewed);

        this.setState({
          quoteModified: false
        });
      }
      catch(err) {
        if (err instanceof AppError) {
          errorReturned.push(err.message);
        }
        else {
          errorReturned.push(err.toString());
        }

        this.setState({
          errorReturned: errorReturned
        });
      }
    }
  }

  handleQuoteSend = async () => {
    const { quoteViewed } = this.state;
    const errorReturned = [];

    if (quoteViewed) {
      try {
        await quoteViewed.send();

        this.setState({
          modalOpen: false,
          quoteViewed: null
        });
      }
      catch(err) {
        if (err instanceof AppError) {
          errorReturned.push(err.message);
        }
        else {
          errorReturned.push(err.toString());
        }

        this.setState({
          errorReturned: errorReturned
        });
      }
    }
  }

  selectAll = e => {
    e.target.select();
  }

  componentDidMount() {
    const { shop } = this.props;
    shop.quotes.loadQuotes();
    shop.user.loadUsers();
  }

  renderTabContent(tab, quotes) {

    const { shop } = this.props;
    const pagesArr =  [];
    for (let i = 1; i <= this.getMaxPageForTab(tab); i++) {
      pagesArr.push(i);
    }

    return(
        <Tab.Pane key={'tab_' + tab}>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Contact</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Assigned To</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell collapsing></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!shop.quotes.isLoading && quotes.map((quote) => { 
                
                let userAssigned = 'Unassigned';

                if (quote.userAssigned && quote.quoteType !== 'Distributor')
                  userAssigned = quote.userAssigned.company;
                else if (quote.quoteType === 'Distributor')
                  userAssigned = 'N/A';

                return (
                  <Table.Row key={quote.id}>
                    <Table.Cell>{quote.company}</Table.Cell>
                    <Table.Cell>{quote.firstName} {quote.lastName}</Table.Cell>
                    <Table.Cell>{quote.phone}</Table.Cell>
                    <Table.Cell>{quote.email}</Table.Cell>
                    <Table.Cell>{userAssigned}</Table.Cell>
                    <Table.Cell>{moment(quote.parsedDateCreated).format('MM/DD/YYYY')}</Table.Cell>
                    <Table.Cell collapsing><Button content='Details' icon='file alternate' labelPosition='left' onClick={() => { this.showQuote(quote)} } /></Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='7'>
                  <Menu floated='right' pagination>
                    <Menu.Item key={'prev'} as='a' onClick={this.decrementPage} disabled={this.getCurrentPageForTab(tab) == 1} icon>
                      <Icon name='chevron left' />
                    </Menu.Item>
                    {pagesArr.map((page) => (
                        <Menu.Item key={'page' + page}  as='a' onClick={this.handlePageChange} active={this.getCurrentPageForTab(tab) == page}>{page}</Menu.Item>
                    ))}
                    <Menu.Item key={'next'} as='a' onClick={this.incrementPage} disabled={this.getCurrentPageForTab(tab) == this.getMaxPageForTab(tab)} icon>
                      <Icon name='chevron right' />
                    </Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

        </Tab.Pane>
    )
  }

  renderDistributors = () => {
    const { shop } = this.props;
    const { quoteViewed } = this.state;
    const distArr = [];

    shop.user.distributors.forEach( (user, idx) => {
      let showButton = true;

      if (quoteViewed && quoteViewed.userAssigned && quoteViewed.userAssigned.userId == user.id)
        showButton = false;

      distArr.push(
        <Table.Row key={idx}>
          <Table.Cell>{user.company}</Table.Cell>
          <Table.Cell>{user.firstName} {user.lastName}</Table.Cell>
          <Table.Cell>{showButton && <Button content='Assign' onClick={() => { this.handleQuoteAssign(user)} } />}</Table.Cell>
        </Table.Row>
      );
    });

    return distArr;
  }

  render() {
    const { shop } = this.props;
    const { modalOpen, quoteViewed, quoteModified, errorReturned, assignModalOpen } = this.state
    const rfqQuotes = this.getQuotesForTab('rfq');
    const crossQuotes = this.getQuotesForTab('cross');

    const panes = [
      { menuItem: 'RFQ', render: () => { return this.renderTabContent('rfq', rfqQuotes) } },
      { menuItem: 'Cross', render: () => { return this.renderTabContent('cross', crossQuotes) } }
    ];

    const quoteTypeOptions = [
      {
        key: 'OEM',
        text: 'OEM',
        value: 'OEM'
      },
      {
        key: 'Distributor',
        text: 'Distributor',
        value: 'Distributor'
      }
    ];

    const salesRepOptions = [];
    let selectedSalesRepCompany = '';

    repData.forEach( (rep, idx) => {
      salesRepOptions.push({
        key: idx + rep.email,
        text: rep.company,
        value: rep.email
      });

      if (quoteViewed && quoteViewed.salesRepEmail === rep.email)
        selectedSalesRepCompany = rep.company;

    });

    let assignedTo = 'Unassigned';


    if (quoteViewed) {
      if (quoteViewed.userAssigned && quoteViewed.quoteType === 'OEM')
        assignedTo = quoteViewed.userAssigned.company;
      else if (quoteViewed.quoteType === 'Distributor')
        assignedTo = 'N/A';
    }


    return(
      <div>
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='file alternate' />
            <Header.Content>Quotes</Header.Content>
          </Header>
          <Tab onTabChange={this.handleTabChange} panes={panes} />
        </Container>

        <Modal
            onClose={() => this.setAssignModalOpen(false)}
            onOpen={() => this.setAssignModalOpen(true)}
            open={assignModalOpen}
            className="quoteAssignModal"
        >
          <Modal.Header>Quote Assignment</Modal.Header>
          <Modal.Content>
          <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Company</Table.HeaderCell>
                  <Table.HeaderCell>Contact</Table.HeaderCell>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.renderDistributors()}
              </Table.Body>
          </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button className='modalbutton' onClick={() => this.setAssignModalOpen(false)}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
            onClose={() => this.setModalOpen(false)}
            onOpen={() => this.setModalOpen(true)}
            open={modalOpen}
            className="quoteDetailsModal"
        >
          <Modal.Header>Quote Details</Modal.Header>
          <Modal.Content>
            {quoteViewed &&
            <Table celled striped>

              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>Company</Table.Cell>
                  <Table.Cell>{quoteViewed.company}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Name</Table.Cell>
                  <Table.Cell>{quoteViewed.firstName} {quoteViewed.lastName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Phone</Table.Cell>
                  <Table.Cell>{quoteViewed.phone}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Email</Table.Cell>
                  <Table.Cell>{quoteViewed.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Type</Table.Cell>
                  <Table.Cell>{quoteViewed.userAssigned ? quoteViewed.quoteType : ''} {!quoteViewed.userAssigned && <Form.Dropdown selection options={quoteTypeOptions} value={quoteViewed.quoteType} onChange={this.handleQuoteTypeUpdate} /> }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Sales Rep</Table.Cell>
                  <Table.Cell>{quoteViewed.userAssigned ? selectedSalesRepCompany: ''} {!quoteViewed.userAssigned && <Form.Dropdown className='salesRepDropdown' selection options={salesRepOptions} value={quoteViewed.salesRepEmail} onChange={this.handleSalesRepUpdate} /> }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Assigned To</Table.Cell>
                  <Table.Cell>{assignedTo}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>Comments</Table.Cell>
                  <Table.Cell>{quoteViewed.comments}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            }
            {quoteViewed && quoteViewed.entries && quoteViewed.entries.length > 0 && quoteViewed.entries[0].part &&
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Part No.</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Material</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Plating</Table.HeaderCell>
                  <Table.HeaderCell collapsing> </Table.HeaderCell>
                  <Table.HeaderCell collapsing>Qty/Price 1</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Qty/Price 2</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Qty/Price 3</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {[...quoteViewed.entries.values()].map((entry, idx) => ([
                    <Table.Row key={idx}>
                      <Table.Cell>{entry.part.partNumber}</Table.Cell>
                      <Table.Cell>{entry.part.description}</Table.Cell>
                      <Table.Cell>{entry.part.material ? entry.part.material.name : ''}</Table.Cell>
                      <Table.Cell collapsing>
                        {entry.part.plating ? entry.part.plating.name : ''}<br />
                        
                      </Table.Cell>
                      <Table.Cell className='priceLabels'>
                        Qty<br />
                        Price<br />
                        Plating<br />
                        Lead&nbsp;Time<br />
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Form.Input className="quantityInput" label='# ' value={entry.quantity1} onFocus={this.selectAll} onChange={e => this.handleQuantity1Update(e, entry)} />
                        <Form.Input className="priceInput" label='$ ' value={entry.price1} onFocus={this.selectAll}  onChange={e => this.handlePrice1Update(e, entry)} />
                        <Form.Input className="platingPriceInput" label='$ ' value={entry.platingPrice1} onFocus={this.selectAll}  onChange={e => this.handlePlatingPrice1Update(e, entry)} />
                        <Form.Input className="deliveryInput" value={entry.delivery1} onFocus={this.selectAll}  onChange={e => this.handleDelivery1Update(e, entry)} />
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Form.Input className="quantityInput" label='# ' value={entry.quantity2} onFocus={this.selectAll}  onChange={e => this.handleQuantity2Update(e, entry)} />
                        <Form.Input className="priceInput" label='$ '  value={entry.price2} onFocus={this.selectAll}  onChange={e => this.handlePrice2Update(e, entry)} />
                        <Form.Input className="platingPriceInput" label='$ ' value={entry.platingPrice2} onFocus={this.selectAll}  onChange={e => this.handlePlatingPrice2Update(e, entry)} />
                        <Form.Input className="deliveryInput" value={entry.delivery2} onFocus={this.selectAll}  onChange={e => this.handleDelivery2Update(e, entry)} />
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Form.Input className="quantityInput" label='# ' value={entry.quantity3} onFocus={this.selectAll}  onChange={e => this.handleQuantity3Update(e, entry)} />
                        <Form.Input className="priceInput" label='$ '  value={entry.price3} onFocus={this.selectAll}  onChange={e => this.handlePrice3Update(e, entry)} />
                        <Form.Input className="platingPriceInput" label='$ ' value={entry.platingPrice3} onFocus={this.selectAll}  onChange={e => this.handlePlatingPrice3Update(e, entry)} />
                        <Form.Input className="deliveryInput" value={entry.delivery3} onFocus={this.selectAll}  onChange={e => this.handleDelivery3Update(e, entry)} />
                      </Table.Cell>
                    </Table.Row>,
                    <Table.Row>
                      <Table.Cell>Fascomp Comments</Table.Cell>
                      <Table.Cell colSpan='7'><TextArea className='adminCommentsInput' value={entry.adminComments} onFocus={this.selectAll} onChange={e => this.handleAdminCommentsUpdate(e, entry)} placeholder='Comments to distributor' /></Table.Cell>
                    </Table.Row>
                ]))}
              </Table.Body>
            </Table>}
            {quoteViewed && quoteViewed.entries && quoteViewed.entries.length > 0 && quoteViewed.entries[0].partNumberToCross &&
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Cross Part No.</Table.HeaderCell>
                  <Table.HeaderCell>FC Part No.</Table.HeaderCell>
                  <Table.HeaderCell> </Table.HeaderCell>
                  <Table.HeaderCell collapsing>Qty/Price 1</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Qty/Price 2</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Qty/Price 3</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {[...quoteViewed.entries.values()].map((entry, idx) => ([
                    <Table.Row key={idx}>
                      <Table.Cell>{entry.partNumberToCross}</Table.Cell>
                      <Table.Cell><Form.Input className="fascompPartNumberInput" value={entry.fascompPartNumber} onChange={e => this.handleFascompPartNumberUpdate(e, entry)} /></Table.Cell>
                      <Table.Cell className='priceLabels'>
                        Qty<br />
                        Price<br />
                        Plating<br />
                        Lead&nbsp;Time<br />
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Form.Input className="quantityInput" label='# ' value={entry.quantity1} onFocus={this.selectAll}  onChange={e => this.handleQuantity1Update(e, entry)} />
                        <Form.Input className="priceInput" label='$ ' value={entry.price1} onFocus={this.selectAll}  onChange={e => this.handlePrice1Update(e, entry)} />
                        <Form.Input className="platingPriceInput" label='$ ' value={entry.platingPrice1} onFocus={this.selectAll}  onChange={e => this.handlePlatingPrice1Update(e, entry)} />
                        <Form.Input className="deliveryInput" value={entry.delivery1} onFocus={this.selectAll}  onChange={e => this.handleDelivery1Update(e, entry)} />
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Form.Input className="quantityInput" label='# ' value={entry.quantity2} onFocus={this.selectAll}  onChange={e => this.handleQuantity2Update(e, entry)} />
                        <Form.Input className="priceInput" label='$ '  value={entry.price2} onFocus={this.selectAll}  onChange={e => this.handlePrice2Update(e, entry)} />
                        <Form.Input className="platingPriceInput" label='$ ' value={entry.platingPrice2} onFocus={this.selectAll}  onChange={e => this.handlePlatingPrice2Update(e, entry)} />
                        <Form.Input className="deliveryInput" value={entry.delivery2} onFocus={this.selectAll}  onChange={e => this.handleDelivery2Update(e, entry)} />
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Form.Input className="quantityInput" label='# ' value={entry.quantity3} onFocus={this.selectAll}  onChange={e => this.handleQuantity3Update(e, entry)} />
                        <Form.Input className="priceInput" label='$ '  value={entry.price3} onFocus={this.selectAll}  onChange={e => this.handlePrice3Update(e, entry)} />
                        <Form.Input className="platingPriceInput" label='$ ' value={entry.platingPrice3} onFocus={this.selectAll}  onChange={e => this.handlePlatingPrice3Update(e, entry)} />
                        <Form.Input className="deliveryInput" value={entry.delivery3} onFocus={this.selectAll}  onChange={e => this.handleDelivery3Update(e, entry)} />
                      </Table.Cell>
                    </Table.Row>,
                    <Table.Row>
                      <Table.Cell>Fascomp Comments</Table.Cell>
                      <Table.Cell colSpan='7'><TextArea className='adminCommentsInput' value={entry.adminComments} onFocus={this.selectAll} onChange={e => this.handleAdminCommentsUpdate(e, entry)} placeholder='Comments to distributor' /></Table.Cell>
                    </Table.Row>
                ]))}
              </Table.Body>
            </Table>}
            {(errorReturned && errorReturned.length > 0) && <Message negative>
              <Message.Header>We're sorry, there was a problem with the user submission</Message.Header>
              <p>{errorReturned}</p>
            </Message>}
          </Modal.Content>
          <Modal.Actions>
            <Button className='modalbutton' disabled={!quoteModified} onClick={this.handleQuoteUpdate}>
              Update
            </Button>
            <Button className='modalbutton' onClick={() => this.setModalOpen(false)}>
              Close
            </Button>
            {!quoteModified && quoteViewed && quoteViewed.quoted && quoteViewed.quoteType == 'OEM' &&
            <Button className='modalbutton' onClick={this.showQuoteAssign}>
              { quoteViewed.userAssigned ? 'Rea' : 'A' }ssign
            </Button>}
            {!quoteModified && quoteViewed && quoteViewed.quoted && (quoteViewed.quoteType == 'Distributor' || (quoteViewed.quoteType == 'OEM' && quoteViewed.userAssigned)) &&
            <Button className='modalbutton' onClick={this.handleQuoteSend}>
              { quoteViewed.userAssigned ? 'Res' : 'S' }end
            </Button>}
          </Modal.Actions>
        </Modal>
        <ScrollToTopOnMount />
      </div>
    )
  }
}

export default inject("shop")(observer(PageAdminQuotes))