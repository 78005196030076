import React from "react"
import { observer, inject } from "mobx-react"
import logo from "../logo.svg"
import "./App.css"

import Items from "./Items"
import Projects from "./Projects"
import ItemDetails from "./ItemDetails"
import ProjectDetails from "./ProjectDetails"
import Cart from "./Cart"
import PageHome from "./PageHome"
import PageCatalog from "./PageCatalog"
import PageTechnical from "./PageTechnical"
import PageCrossRef from "./PageCrossRef"
import PageRFQ from "./PageRFQ"
import PageLocalReps from "./PageLocalReps"
import PageCreatePrint from "./PageCreatePrint"
import PageContactUs from "./PageContactUs"
import PageVideoResources from "./PageVideoResources"
import PagePlating from "./PagePlating"

import PageMaterial from "./PageMaterial"
import PageDepthOfThreadF from "./PageDepthOfThreadF"
import PageDepthOfThreadMF from "./PageDepthOfThreadMF"
import PageTorqueValues from "./PageTorqueValues"
import PageTermsOfSale from "./PageTermsOfSale"
import PageAdminQuotes from "./PageAdminQuotes"
import PageAdminUsers from "./PageAdminUsers"

import PagePartGroup from "./PagePartGroup"

import LoanSelect from "./LoanSelect"
import DevTools from "./DevTools"
import ResponsiveContainer from './ResponsiveContainer'
import { Loader, Segment, Dimmer } from 'semantic-ui-react'
import PagePartCategoryLanding from "./PagePartCategoryLanding"

/*{shop.isLoading ? <h1>Loading...</h1> : renderPage(shop.view)}*/
const App = inject("shop")(
    observer(({ shop }) => {
        return (
            <ResponsiveContainer>
                {shop.isLoading && (shop.view.page == 'partcross' && shop.isLoadingSingle || shop.view.page == 'part' && shop.isLoadingSingle || (shop.view.page == 'partgroup' && shop.isLoadingSingle) || shop.view.page == 'rfq' || shop.view.page == 'crossref') ? <Dimmer inverted active><Loader size='massive'>Loading</Loader></Dimmer> : renderPage(shop.view)}
            </ResponsiveContainer>
        )
    })
)

/*
const App = inject("shop")(
    observer(({ shop }) => (
        <div>
            <div className="App">
                <AppHeader />
                <AppMenu>
                    <AppMenuItem onClick={() => shop.view.openItemsPage()}>Shop</AppMenuItem>
                    <AppMenuItem onClick={() => shop.view.openCartPage()}>Cart {shop.cart.totalQuantity > 0 ? '(' + shop.cart.totalQuantity + ')' : '' }</AppMenuItem>
                    <AppMenuItem onClick={() => shop.view.openProjectsPage()}>Projects</AppMenuItem>
                </AppMenu>
                {shop.isLoading ? <h1>Loading...</h1> : renderPage(shop.view)}
            </div>
            <DevTools />
        </div>
    ))
)
*/
function renderPage(viewStore) {
    switch (viewStore.page) {
        case "items":
            return <Items />
        case "item":
            const item = viewStore.selectedItem
            if (item) return <ItemDetails item={item} />
            else return <h1>Item ${viewStore.selectedItemId} not found!</h1>
        case "projects":
            return <Projects />
        case "project":
            const project = viewStore.selectedProject
            if (project) return <ProjectDetails project={project} />
            else return <h1>Project ${viewStore.selectedProjectId} not found!</h1>
        case "cart":
            return <Cart />
        case "home":
            return <PageHome />
        case "catalog":
            return <PageCatalog />
        case "technical":
            return <PageTechnical />
        case "rfq":
            return <PageRFQ />
        case "admin/quotes":
            return <PageAdminQuotes />
        case "admin/users":
            return <PageAdminUsers />    
        case "crossref":
            return <PageCrossRef />
        case "localreps":
            return <PageLocalReps />
        case "createprint":
            return <PageCreatePrint />
        case "contactus":
            return <PageContactUs />
        case "videoresources":
            return <PageVideoResources />

        case "plating":
            return <PagePlating />
        case "material":
            return <PageMaterial />
        case "depthofthreadf":
            return <PageDepthOfThreadF />
        case "depthofthreadmf":
            return <PageDepthOfThreadMF />
        case "torquevalues":
            return <PageTorqueValues />
        case "termsofsale":
            return <PageTermsOfSale />
        case "categorylanding":
            return <PagePartCategoryLanding />    
        case "partgroup":
            const partGroup = viewStore.selectedPartGroup
            if (partGroup) return <PagePartGroup partGroup={partGroup} />
            else return <h1>Part group {viewStore.selectedPartGroupUrlName} was not found!</h1>

        case "part":
            const partGroupPn = viewStore.selectedPartGroup
            if (partGroupPn) return <PagePartGroup partGroup={partGroupPn} selectedPartNumber={viewStore.selectedPartGroupPartNumber} />
            else return <h1>Part number {viewStore.selectedPartGroupPartNumber} was not found!</h1>

        case "partcross":
            const partGroupCr = viewStore.selectedPartGroup
            if (partGroupCr) return <PagePartGroup partGroup={partGroupCr} selectedPartNumber={viewStore.shop.partGroupStore.crossPartNumber(viewStore.selectedPartGroupPartNumberCross)} selectedPartNumberCross={viewStore.selectedPartGroupPartNumberCross} />
            else return <h1>Cross reference for part number {viewStore.selectedPartGroupPartNumberCross} was not found!</h1>

        default:
            return "Sorry, the page requested was not found"
    }
}

const AppHeader = () => (
    <div className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>Welcome to the React MobX State Tree example!</h2>
    </div>
)

const AppMenu = ({ children }) => <ul className="App-menu">{children}</ul>

const AppMenuItem = ({ onClick, children }) => (
    <li>
        <a onClick={onClick}>{children}</a>
    </li>
)

export default App