import { observer, inject } from "mobx-react"
import React, { Component } from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react'
import ProductCardLines, { cardData } from './ProductCardLines'
import ScrollToTopOnMount from './ScrollToTopOnMount'

class HomepageLayout extends Component {

  componentDidMount(){
    document.title = 'Fascomp Electronic Hardware'
  }

  handleClickPartGroup = (e, id, urlName) => {
    const { openPartGroupPageByUrlName, openPartGroupPageById } = this.props.shop.view;
    if (id)
      openPartGroupPageById(id);
    else
      openPartGroupPageByUrlName(urlName);
  };

  render() {
    return(
      <div>
        <Container style={{ padding: '4em 0em' }} >
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as='h3' style={{ fontSize: '2em' }}>World's Leading Supplier</Header>
                <p style={{ fontSize: '1.33em' }}>
                  Fascomp is the world's leading supplier of fine quality, aggressively priced electronic hardware, boasting the world's largest inventory. Fascomp products are sold throughout the world predominately through our worldwide network of Distributors.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  We are extremely proficient at producing customer specific screw machine products quickly and at very competitive prices as well. With 40 years in the business, the managing partners have extensive knowledge of electronic hardware and its applications.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <ProductCardLines lines={cardData} onClickPartGroup={this.handleClickPartGroup} />

        <Segment style={{ padding: '8em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column floated='left' width={6}>
                <Image
                  bordered
                  rounded
                  size='large'
                  src='images/home_customerservice.jpg'
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as='h3' style={{ fontSize: '2em' }}>World Class Customer Service</Header>
                <p style={{ fontSize: '1.33em' }}>
                  Fascomp provides outstanding customer service because we truly understand our customers' needs and expectations. Our customers are the core of our business and we offer you a level of customer service that truly sets Fascomp apart from the competition.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  <ul>
                    <li>Friendly/Knowledgeable Sales Staff</li>
                    <li>Quick Response to Customer Requests</li>
                    <li>Field Engineering Support</li>
                  </ul>
                </p>
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </Segment>


        <Segment style={{ padding: '8em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as='h3' style={{ fontSize: '2em' }}>State of the Art Manufacturing</Header>
                <p style={{ fontSize: '1.33em' }}>
                  Fascomp is extremely proficient at producing customer specific screw machine products quickly and at very competitive prices as well. Each customer order is given individual attention from the initial request through shipment.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  <ul>
                    <li>$15.00 Minimum Order</li>
                    <li>The Most Attractive Prices</li>
                    <li>Blanket Ordering</li>
                    <li>Drop Shipments (Blind Paperwork)</li>
                  </ul>
                </p>
              </Grid.Column>
              <Grid.Column floated='right' width={6}>
                <Image
                  bordered
                  rounded
                  size='large'
                  src='images/home_stateoftheart.jpg'
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>


        <Segment style={{ padding: '8em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column floated='left' width={6}>
                <Image
                  bordered
                  rounded
                  size='large'
                  src='images/home_worldslargest.jpg'
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as='h3' style={{ fontSize: '2em' }}>World's Largest Inventory</Header>
                <p style={{ fontSize: '1.33em' }}>
                  Fascomp maintains deep inventories in both American and Metric sizes. We have World class manufacturing facilities and two warehouse locations so you can Tap into the world's largest inventory. We are an ISO 9001 : 2015 Certified Company.
                </p>
                <p style={{ fontSize: '1.33em' }}>
                  <ul>
                    <li>World's Largest Inventory</li>
                    <li>2 Stocking Locations</li>
                    <li>Fast Production on Specials</li>
                    <li>Just-In-Time Inventory Management</li>
                    <li>Complete Plating Capabilities</li>
                  </ul>
                </p>
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '0em' }} vertical>
          <Grid celled='internally' columns='equal' stackable>
            <Grid.Row textAlign='center'>
              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as='h3' style={{ fontSize: '2em' }}>Fascomp Electronic Hardware</Header>
                <p style={{ fontSize: '1.33em' }}>"The Good Guys"</p>
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as='h3' style={{ fontSize: '2em' }}>Orders</Header>
                <p style={{ fontSize: '1.33em' }}>$15 Minimum Order</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>


        <Segment style={{ padding: '8em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={10}>
                <Header as='h3' style={{ fontSize: '2em' }}>Fascomp's Mission Statement</Header>
                <p style={{ fontSize: '1.33em' }}>
                  We will listen to our customers, analyze the marketplace and work to improve every aspect of our business. We will embrace changes that will benefit our distributors, our employees and ourselves. Fascomp will operate in a conscientious and ethical manner and contribute to the charitable interests of our communities.
                </p>
              </Grid.Column>
              <Grid.Column floated='right' width={4}>
                <Image
                  size='small'
                  src='images/ISO.png'
                  alt='ISO Certified Company'
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <ScrollToTopOnMount />
      </div>
    )
  }
}

export default inject("shop")(observer(HomepageLayout))
