import { when, reaction } from "mobx"
import { types, getParent, getSnapshot, applySnapshot } from "mobx-state-tree"
import { PartSelected } from "./PartGroupStore"
import AppError from "../modules/apperror"
import * as Promise from "bluebird";

const CartEntry = types
    .model("CartEntry", {
        quantity1: 0,
        quantity2: 0,
        quantity3: 0,
        part: PartSelected
    })
    .views(self => ({
        get item() {
            return self.project.item
        }
    }))
    .actions(self => ({
        increaseQuantity1(amount) {
            self.quantity1 += amount
        },
        increaseQuantity2(amount) {
            self.quantity2 += amount
        },
        increaseQuantity3(amount) {
            self.quantity3 += amount
        },
        setQuantity1(amount) {
            self.quantity1 = amount
        },
        setQuantity2(amount) {
            self.quantity2 = amount
        },
        setQuantity3(amount) {
            self.quantity3 = amount
        }
    }))

export const CartStore = types
    .model("CartStore", {
        company: types.optional(types.string, ''),
        firstName: types.optional(types.string, ''),
        lastName: types.optional(types.string, ''),
        phone: types.optional(types.string, ''),
        email: types.optional(types.string, ''),
        entries: types.array(CartEntry),
        comments: types.optional(types.string, ''),
        quoteRequested: types.optional(types.boolean, false),
    })
    .views(self => ({
        get shop() {
            return getParent(self)
        },
        get totalQuantity() {
            return self.entries.reduce((sum, e) => sum + e.quantity1 + e.quantity2 + e.quantity3, 0)
        },

        get canRequestQuote() {

          console.log(self.firstName,
                self.lastName,
                self.phone,
                self.email,
                self.entries,
                self.entries.every(entry => entry.quantity1 > 0 || entry.quantity2 > 0 || entry.quantity3 > 0));

            return (
                self.firstName.length > 0 &&
                self.lastName.length > 0 &&
                self.phone.length > 0 &&
                self.email.length > 0 &&
                self.entries.length > 0 &&
                self.entries.every(entry => entry.quantity1 > 0 || entry.quantity2 > 0 || entry.quantity3 > 0)
            )
        }
    }))
    .actions(self => ({
        afterAttach() {
            if (typeof window !== "undefined" && window.localStorage) {
                when(
                    () => !self.shop.isLoading,
                    () => {
                        self.readFromLocalStorage()
                        reaction(
                            () => getSnapshot(self),
                            json => {
                                window.localStorage.setItem("cart", JSON.stringify(json))
                            }
                        )
                    }
                )
            }
        },
        addPart(partSelected, quantity = 1, notify = true) {
            let entry = self.entries.find(entry => entry.partNumber === partSelected.partNumber)
            if (!entry) {
                self.entries.push({ part: partSelected })
                entry = self.entries[self.entries.length - 1]
            }
            if (notify) self.shop.alert("Added to cart")
        },
        removePart(partNumber) {
          const filteredEntries = self.entries.filter(entry => entry.part.partNumber !== partNumber )
            self.entries.replace(filteredEntries);
        },
        setComments(comments) {
          self.comments = comments;
        },
        setCompany(company) {
          self.company = company;
        },
        setFirstName(firstName) {
          self.firstName = firstName;
        },
        setLastName(lastName) {
          self.lastName = lastName;
        },
        setEmail(email) {
          self.email = email;
        },
        setPhone(phone) {
          self.phone = phone;
        },
        requestQuote() {

          let response;

          return fetch('/api/rfq', {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(getSnapshot(self)), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => {
            response = res;
            return res.json();
          })
          .then(resjson => {
            if (!response.ok) {
              throw Object.assign(new AppError, resjson)
            }
            self.clear();
            console.log('Success:', JSON.stringify(response))
            return response;
          });

          /*
          return new Promise(function(resolve, reject) {
            setTimeout(() => {
              self.clear();
              resolve();
            }, 2000);
          })
          */
        },
        clear() {
            self.entries.clear()
        },
        readFromLocalStorage() {
            const cartData = window.localStorage.getItem("cart")
            if (cartData) applySnapshot(self, JSON.parse(cartData))
        }
    }))