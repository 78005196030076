import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'underscore'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
Search
} from 'semantic-ui-react'
import './DesktopContainer.css'
import HomepageHeading from './HomepageHeading'
import Footer from './Footer'
/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */


const initialState = {
    isLoading: false,
    results: [],
    value: ''
  }

class DesktopContainer extends Component {
  state = initialState

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  handleSearchChange = (e, { value }) => {
    const { searchPartGroupsByPartNumber } = this.props.shop.partGroupStore

    this.setState({ isLoading: true, value });
    const searchResults = [];


    searchPartGroupsByPartNumber(value).then((partsGroupsFound) => {

      partsGroupsFound.forEach((partGroup) => {
        searchResults.push({
          title: partGroup[1].name,
          image: "/images/partGroups/" + partGroup[1].imgSrc,
          urlname: partGroup[1].urlName
        });
      })

      this.setState({
        isLoading: false,
        value,
        results: searchResults
      })

    });
  }





  handleResultSelect = (e, { result }) => {
    const { openPartGroupPageByUrlName } = this.props.shop.view;
    const { shop } = this.props;
    const { value } = this.state;
    shop.setLastSelectedSearch('');
    shop.setLastSelectedSearch(value);
    openPartGroupPageByUrlName(result.urlname);
  }

  render() {
    const { children, shop } = this.props
    const { page, openHomePage, openCatalogPage, openTechnicalPage, openCrossRefPage, openLocalRepsPage, openVideoResourcesPage, openContactUsPage, openRFQPage, openAdminQuotesPage, openAdminUsersPage } = shop.view;
    const fixed = this.state.fixed || shop.view.page !== 'home';
    const { isLoading, value, results } = this.state

    const userMenu = [
      <Menu.Item as='a' href='./catalog' onClick={openCatalogPage} active={page == 'catalog'}>Catalog</Menu.Item>,
      <Menu.Item as='a' href='./rfq' onClick={openRFQPage} active={page == 'rfq'}>RFQ</Menu.Item>,
      <Menu.Item as='a' href='./technical' onClick={openTechnicalPage} active={page == 'technical'}>Technical Info</Menu.Item>,
      <Menu.Item as='a' href='./videoresources' onClick={openVideoResourcesPage} active={page == 'videoresources'}>Video Resources</Menu.Item>,
      <Menu.Item as='a' href='./crossref' onClick={openCrossRefPage} active={page == 'crossref'}>Cross-Ref / RFQ</Menu.Item>,
      <Menu.Item as='a' href='./localreps' onClick={openLocalRepsPage} active={page == 'localreps'}>Sales Representatives</Menu.Item>,
      <Menu.Item as='a' href='./contactus' onClick={openContactUsPage} active={page == 'contactus'}>Contact Us</Menu.Item>,
    ]

    const adminMenu = [
      <Menu.Item as='a' onClick={openAdminQuotesPage} active={page == 'admin/quotes'}>Quotes</Menu.Item>,
      <Menu.Item as='a' onClick={openAdminUsersPage} active={page == 'admin/users'}>Users</Menu.Item>,
    ]

    return (
      <Responsive minWidth="768" className='DesktopContainer'>
        <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
          <Segment inverted textAlign='center' className='DesktopSegment' vertical>
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
              className='DesktopMenu'
            >
              <Image className='menuLogo' src='/images/logo_thin_small.png' alt='Fascomp logo' hidden={!fixed}/>
              <Container>
                <Menu.Item as='a' href='./' onClick={openHomePage} active={page == 'home'}>Home</Menu.Item>

                { !shop.view.page.startsWith('admin/') ? userMenu : adminMenu }

                <Menu.Item position='right' className='partSearch'>
                  <Search inverted={!fixed}  placeholder='Part Search...'
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, {
                      leading: true,
                    })}
                    results={results}
                    value={value}
                    {...this.props}/>
                </Menu.Item>
              </Container>
            </Menu>
            {(page === 'home') && <HomepageHeading />}
          </Segment>
        </Visibility>
        <div className='desktopChildren'>
          <div className={'desktopMain' + (shop.view.isFullWidth ? ' fullWidth' : '')}>{children}</div>
          <Footer />
        </div>
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

export default inject("shop")(observer(DesktopContainer))