import React from "react"
import { inject, observer } from "mobx-react"

const ItemDetails = inject("shop")(
    observer(({ item, shop }) => (
        <section className="Page-item">
            <h2>{item.name}</h2>
            <p>
                <i>Description: {item.description}</i>
            </p>
            <p>Price: ${item.price}</p>

            <button
                onClick={() => {
                    let project = shop.projectStore.addProject({item: item})
                    shop.view.openProjectPage(project)
                }}
            >
                Start Project
            </button>
        </section>
    ))
)

export default ItemDetails