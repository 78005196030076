import React from "react"
import { observer, inject } from "mobx-react"

const PhotoSelector = inject("shop")(
    observer(({ shop, project }) => (
        <section className="Page-items">
            <h1>Add photos</h1>

            {shop.sortedPhotos.map(photo => <Photo key={photo.id} photo={photo} project={project} />)}

        </section>
    ))
)

const Photo = inject("shop")(
    observer(({ photo, shop, project }) => (

        <img src={photo.url} alt="" width="100" onClick={e => {
            project.addPhoto(photo, project.pageCount)
        }} />


    ))
)

export default PhotoSelector