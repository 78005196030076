import React from "react"
import { inject, observer } from "mobx-react"
import PhotoSelector from "./PhotoSelector"
import IsolatedComponent from "./IsolatedComponent"

const ProjectDetails = inject("shop")(
    observer(({ project, shop }) => (
        <section className="Page-item">
            <button
                onClick={() => {
                    shop.cart.addProject(project);
                }}
            >
                Add to Cart
            </button>
            <h2>{project.name}</h2>
            <select value={project.item.id}
                onChange={updateProjectType.bind(null, project, shop)}>
            {shop.sortedAvailableItems.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
            <p>Item: {project.item.name}</p>
            <p>Photo count: {project.photoCount}</p>
            <p>Stretch to Cover: {project.item.stretchToCover ? "true" : "false"}</p>
            <p>Async Reaction Completed: {project.asyncReactionCompleted ? "true" : "false"}</p>
            <p>Multiple Async Actions Status: {project.multipleAsyncActionsStatus}</p>
            <p>Async Action 1 Status: {project.asyncAction1Status}</p>
            <p>Async Action 2 Status: {project.asyncAction2Status}</p>
            <p>
            <button
                onClick={() => {
                    project.multipleAsyncActions();
                }}
            >
                Multiple Async
            </button>
            </p>
            <p>
            <button disabled={!project.item.multiplePages}
                onClick={() => {
                    project.addPage();
                }}
            >
                Add Page
            </button>
            </p>
            <p>Toggle stretch to cover on underlying object
            <button
                onClick={() => {
                    let item = shop.items.get(project.item.id);
                    item.toggleStretchToCover();
                }}
            >
                Toggle
            </button>
            </p>
            <PhotoSelector project={project} />
            {project.pages.map((page, index) => <Page page={page} key={index} index={index} />)}

            Isolated Component Reported Status: {project.isolatedComponentStatus}
            <IsolatedComponent setStatus={project.setIsolatedComponentStatus} />

            <p>Derived Value: {project.derivedFromOptional}</p>
            <p>
            <button
                onClick={() => {
                    project.optionalToNull();
                }}
            >
                Derived to Null
            </button>
            <button
                onClick={() => {
                    project.optionalToUndefined();
                }}
            >
                Derived to Undefined
            </button>
             <button
                onClick={() => {
                    project.optionalToString();
                }}
             >
                Derived to String
            </button>
            </p>
        </section>
    ))
)

const Page = inject("shop")(
    observer(({ index, page }) => (
        <section className="Page-items">
            <h1>Page {index + 1}</h1>
            {page.photos.map((photo, index) => <img src={photo.url} key={index + '-' + photo.id} alt="" width="100" />)}
        </section>
    ))
)

function updateProjectType(project, shop, e) {
    if (e.target.value)
    {
        let item = shop.items.get(e.target.value);
        project.setItem(item)
    }

}

export default ProjectDetails