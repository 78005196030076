const repData = [
  {
    company: 'All American Systems',
    contact: 'Steve Urhausen',
    street: '800 W. 5th Avenue - Suite 201D',
    city: 'Naperville',
    state: 'IL',
    zip: '60563',
    phone: '630-778-6887',
    fax: '630-778-8138',
    mobile: '630-258-8546',
    email: 'steve@allamericansystems.com',
    website: 'www.allamericansystems.com',
    area: ['IL', 'WI', 'IA', 'MN', 'IN', 'NE', 'MO', 'KS'],
    location: { lat: 41.7798442, lon: -88.1664693 }
  },
 {
    company: 'Technical Marketing Inc.',
    contact: 'Frank Salmick',
    street: '170 120th Avenue NE, Suite 101',
    city: 'Bellevue',
    state: 'WA',
    zip: '98005',
    phone: '425-444-6872',
    email: 'frank.a.salmick@tmi-sales.com',
    website: '',
    area: ['OR', 'WA', 'ID', 'MT'],
    location: { lat: 47.611663, lon: -122.1809673 }
  },
  {
    company: 'Atlas Industry Marketing',
    contact: 'Alan Lindahl',
    street: '321 Plantation Dr. East',
    city: 'Burleson',
    state: 'TX',
    zip: '76028-1488',
    phone: '817-447-1750',
    fax: '817-447-1320',
    mobile: '817-247-8759',
    email: 'allindahl@sbcglobal.net',
    website: 'www.atlas-im.com',
    area: ['TX', 'OK', 'AR', 'LA'],
    location: { lat: 32.4985901, lon: -97.2745976 }
  },
  /*
  {
    company: 'John Gourley Associates',
    contact: 'Jack Gourley',
    street: '16 Lower Rd',
    city: 'Lakeville',
    state: 'PA',
    zip: '18438',
    phone: '570-226-7475',
    fax: '',
    mobile: '215-421-2937',
    email: 'jackgou@ptd.net',
    website: '',
    stateHeadings: {
      PA: 'Eastern PA'
    },
    area: ['PA', 'NJ', 'MD', 'DE'],
    location: { lat: 41.423831, lon: -75.2589127 }
  },
  */
  {
    company: 'Ventura Industrial Products, LLC',
    contact: 'Jackie Ventura',
    street: '9520 S.Skyland Dr.',
    city: 'Macedonia',
    state: 'OH',
    zip: '44056',
    phone: '330-467-0102',
    fax: '330-467-0108',
    mobile: '216-338-8485',
    email: 'jv@jackieventura.com',
    website: '',
    stateHeadings: {
      NY: 'Upstate New York',
      PA: 'Western PA'
    },
    area: ['NY', 'MI', 'OH', 'KY', 'PA'],
    location: { lat: 41.338112, lon: -81.5006623 }
  },
  {
    company: 'Steers Associates',
    contact: 'Rob Steers',
    street: '610 Willow Knoll Drive',
    city: 'Marietta',
    state: 'GA',
    zip: '30067',
    phone: '404-964-8498',
    fax: '',
    mobile: '',
    email: 'rwsteers@aol.com',
    website: '',
    area: ['FL', 'AL', 'GA'],
    location: { lat: 33.941224, lon: -84.4186551 }
  },
  /*
  {
    company: 'S.A. Welch & Associates',
    contact: 'Stephen (Steve) Welch',
    street: '802 Worchester Place',
    city: 'Simpsonville',
    state: 'SC',
    zip: '29680',
    phone: '864-325-2451',
    fax: '888-366-5023',
    mobile: '',
    email: 'steveawelch@hotmail.com',
    website: '',
    area: ['VA', 'WV', 'NC', 'SC', 'TN'],
    location: { lat: 34.7090656, lon: -82.2880765 }
  },
  */
  {
    company: 'CJRJ Sales Inc.',
    contact: 'Rick Jaszek',
    street: '604 - 478 Pearl St.',
    city: 'Burlington',
    state: 'Ontario',
    zip: '',
    country: 'Canada L7R 2N3',
    phone: '905-975-8896',
    fax: '',
    email: 'rpjaszek@sympatico.ca',
    website: '',
    area: ['Canada'],
    location: { lat: 43.3279756, lon: -79.7994629 }
  },
  {
    company: 'Murty Associates',
    contact: 'Tricia Murty',
    street: '623 Charles Street Avenue',
    city: 'Baltimore',
    state: 'MD',
    zip: '21204',
    phone: '410-340-8916',
    fax: '',
    email: 'tricia@murtysales.com',
    website: '',
    stateHeadings: {
      PA: 'Eastern PA'
    },
    area: ['PA', 'NJ', 'DE', 'MD', 'VA', 'WV'],
    location: { lat: 39.4054694, lon: -76.6188046 }
  },
  {
    company: 'Southeast Sales and Engineering',
    contact: 'Bo Oliver',
    street: '101 Lake Drive',
    city: 'LaGrange',
    state: 'GA',
    zip: '30241',
    phone: '706-333-0695',
    fax: '',
    email: 'bo@sesales-eng.com',
    website: '',
    area: ['NC', 'SC', 'TN', 'MS'],
    location: { lat: 32.967365, lon: -84.9812343 }
  },
  {
    company: 'Fascomp Electronic Hardware',
    contact: 'Contact Fascomp for information on local reps in this area',
    street: '',
    city: '',
    state: '',
    zip: '',
    phone: '(407) 226-2112',
    fax: '(407) 226-3370',
    email: 'sales@fascomp.com',
    stateHeadings: {
      // CA: 'Southern California',
      NY: 'Metro New York'
    },
    area: ['CA', 'NY'],
    location: { lat: 32.4985901, lon: -97.2745976 }
  }
]

export default repData