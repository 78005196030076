import { types, flow, getParent } from "mobx-state-tree"
import { User } from "./UserStore"

export const AuthStore = types
    .model("AuthStore", {
        authFailed: types.optional(types.boolean, false),
        authRequested: types.optional(types.boolean, false),
        authUser: types.maybe(User),
        username: '',
        password: ''
    })
    .views(self => ({
        get shop() {
            return getParent(self)
        }
    }))
    .actions(self => {
        let authCallbacks = [];
        let authenticatePromise;

        function addAuthCallback(callback) {
            authCallbacks.push(callback);
        }

        function setAuthRequested(authRequested, callback)
        {
            self.authRequested = authRequested;

            if (callback)
                addAuthCallback(callback);
        }

        function setAuthFailed(authFailed) {
            self.authFailed = authFailed;
        }

        function setUsername(username) {
            self.username = username;
        }

        function setPassword(password) {
            self.password = password;
        }

        const _authenticate = flow(function* authenticate(username, password) {
            self.authFailed = false;

            try {
                const headers = new Headers();
                headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

                const result = yield fetch("/api/authenticate", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Basic ' + btoa(username + ":" + password)
                    }
                });
                
                if (result.status == 200) {
                    const json = yield result.json();
                    self.authUser = User.create(json);

                    while (authCallbacks.length > 0) {
                        const cb = authCallbacks.shift();
                        cb(self.authUser);
                    }

                    self.authRequested = false;
                    self.authFailed = false;
                }
                else {
                    self.authFailed = true;
                }

            } catch (err) {
                console.error("Failed to authenticate ", err)
            }
        })


        function authenticate(username, password) {
            //if (authenticatePromise)
            //    return authenticatePromise;

            if (!username && !password) {
                username = self.username;
                password = self.password;
            }

            return authenticatePromise = _authenticate(username, password);
            //return authenticatePromise;
        }
        
        return {
            addAuthCallback,
            setAuthRequested,
            setAuthFailed,
            setUsername,
            setPassword,
            authenticate
        }
    });