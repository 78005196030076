import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Form,
  Button,
  Segment,
  Message,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import MaskedInput from 'react-text-mask'
import ReactGA from "react-ga4";
import ScrollToTopOnMount from './ScrollToTopOnMount'


class PageCrossRef extends Component {

  state = {
    quoteRequested: false,
    quoteComplete: false,
    errorReturned: null
  }

  componentDidMount(){
    document.title = 'Cross Reference - Fascomp Electronic Hardware'
  }

  handleRemoveClick = (e, index) => {
    const { crossRef } = this.props.shop;
    crossRef.removePart(index);
  }

  handleAddPart = () => {
    const { crossRef } = this.props.shop;
    crossRef.addPart();
  }

  handlePartNumberToCrossUpdate = (e, entry) => {
    if (e.target.value) entry.setPartNumberToCross(e.target.value)
  }
  handleFascompPartNumberUpdate = (e, entry) => {
    if (e.target.value) entry.setFascompPartNumber(e.target.value)
  }
  handleQuantity1Update = (e, entry) => {
    if (e.target.value) entry.setQuantity1(parseInt(e.target.value, 10))
  }
  handleQuantity2Update = (e, entry) => {
    if (e.target.value) entry.setQuantity2(parseInt(e.target.value, 10))
  }
  handleQuantity3Update = (e, entry) => {
    if (e.target.value) entry.setQuantity3(parseInt(e.target.value, 10))
  }

  handleRequestQuote = (e, entry) => {
    e.preventDefault();
    const { crossRef } = this.props.shop;

    this.setState({ quoteRequested: true, errorReturned: null });

    const cartItems = [];
    crossRef.entries.forEach(entry => {
      cartItems.push({
        item_id: entry.partNumberToCross,
        quantity: entry.quantity1
      });
    });


    crossRef.requestQuote().then((result) => {
      this.setState({ quoteComplete: true });

      ReactGA.event('purchase', {
        transaction_id: result.id,
        items: cartItems
      });

      window.scrollTo(0, 0);
    }).catch((err) => {
      let errorMessage = 'An unknown error occurred'

      console.log('ERROR:', err);

      if (err.constructor.name === 'AppError')
        errorMessage = err.errors[0].message;

      this.setState({
        quoteRequested: false,
        errorReturned: errorMessage
      });
    });
    return false;
  }

  renderEntry(entry, index) {
    return (
      <Segment>
        <Form.Group>
          <Form.Input label="P/N to cross" value={entry.partNumberToCross} onChange={ e => this.handlePartNumberToCrossUpdate(e, entry)}  width={8} />
          <Form.Input label="Qty 1" value={entry.quantity1} onChange={e => this.handleQuantity1Update(e, entry)} width={2} />
          <Form.Input label="Qty 2" value={entry.quantity2} onChange={e => this.handleQuantity2Update(e, entry)} width={2} />
          <Form.Input label="Qty 3" value={entry.quantity3} onChange={e => this.handleQuantity3Update(e, entry)} width={2} />
          <div className="two wide field">
            <label>&nbsp;</label>
            <div className="ui input"><Form.Button onClick={(e) => this.handleRemoveClick(e, index)}>Remove</Form.Button></div>
          </div>
        </Form.Group>
      </Segment>)
  }


  render() {
    const { crossRef } = this.props.shop;
    const { quoteComplete, quoteRequested, errorReturned } = this.state;

    return (
      <div>
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='random' />
            <Header.Content>Cross Reference / RFQ</Header.Content>
          </Header>
          {!quoteComplete && <Form>

            <Dimmer active={quoteRequested} inverted>
              <Loader inverted size='massive' content='Loading' />
            </Dimmer>
            <Form.Input label='Company Name' placeholder='Company Name' width={16} value={crossRef.company} onChange={(e) => crossRef.setCompany(e.target.value)} />
            <Form.Group>
              <Form.Input label='First Name' placeholder='First Name' width={8} value={crossRef.firstName} onChange={(e) => crossRef.setFirstName(e.target.value)} />
              <Form.Input label='Last Name' placeholder='Last Name' width={8} value={crossRef.lastName} onChange={(e) => crossRef.setLastName(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Input label='Phone' placeholder='(XXX) XXX-XXXX' width={8}>
                <MaskedInput
                  mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  placeholder="(XXX) XXX-XXXX"
                  value={crossRef.phone}
                  onChange={(e) => crossRef.setPhone(e.target.value)}
                />
              </Form.Input>
              <Form.Input label='Email' placeholder='Email' width={8} value={crossRef.email} onChange={(e) => crossRef.setEmail(e.target.value)} />
            </Form.Group>
            {crossRef.entries.length == 0 && <Message
              icon='exclamation triangle'
              header="You don't have any parts in your RFQ!"
              content="To get started, click the Add Part button below and enter your parts to be cross referenced."
            />}
            {crossRef.entries.map((entry, index) => this.renderEntry(entry, index))}
            <Form.Button onClick={this.handleAddPart}>Add Part</Form.Button>
            <Form.TextArea label='Comments or Questions' placeholder='Anything else you want to tell us...' onChange={(e) => crossRef.setComments(e.target.value)} />
            {errorReturned && <Message negative>
              <Message.Header>We're sorry there was a problem submitting your quote</Message.Header>
              <p>{errorReturned}</p>
            </Message>}
            <Form.Group>
              <Form.Button onClick={this.handleRequestQuote} disabled={!crossRef.canRequestQuote}>Get Quote</Form.Button>
            </Form.Group>
          </Form>}
          {quoteComplete && <Message
              icon='check'
              header="Thank you!"
              content="Your request for quote has been forwarded to our Sales Department and you will receive a response shortly."
            />}
        </Container>
        <ScrollToTopOnMount />
      </div>)
    }
}

export default inject("shop")(observer(PageCrossRef))