import React from "react"
import { observer, inject } from "mobx-react"

const Items = inject("shop")(
    observer(({ shop }) => (
        <section className="Page-items">
            <h1>Available items</h1>
            <ol>
                {shop.sortedAvailableItems.map(item => <ItemEntry key={item.id} item={item} />)}
            </ol>
        </section>
    ))
)

const ItemEntry = inject("shop")(
    observer(({ item, shop }) => (
        <li>
            <a
                href={`/item/${item.id}`}
                onClick={e => {
                    e.preventDefault()
                    shop.view.openItemPage(item)
                    return false
                }}
            >
                {item.name}
            </a>
        </li>
    ))
)

export default Items