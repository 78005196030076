import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import {
  Container,
  Icon,
  Header,
  List,
  Message,
  Segment
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PageTermsOfSale = () => {

  useEffect(() => {
    document.title = 'Terms of Sale - Fascomp Electronic Hardware';
  }, []);

  return (
  <div>
    <Container>
      <Header as='h2' dividing textAlign='center'>
        <Icon name='info circle' />
        <Header.Content>Terms of Sale</Header.Content>
      </Header>
      <Segment>
        <List size="large">
          <List.Item>
            <List.Header>F.O.B.</List.Header>
            <List.Description>
              Orlando, Florida.
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Payment terms</List.Header>
            <List.Description>
              1 Percent 10 days net 30 days (upon credit approval).
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Quotations</List.Header>
            <List.Description>
              Prices quoted are valid for 60 days. Clerical and or typographical errors are subject to correction.
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Shipments</List.Header>
            <List.Description>
              Fascomp welcomes blanket orders and will accept schedules up to a 12 month period at no additional charge. Fascomp also will drop ship direct to your customer, using your paperwork, at no additional charge. Standard items will be shipped to the quantity ordered. Custom (non-catalog) items may be over-shipped by up to 10 percent.
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Returns</List.Header>
            <List.Description>
              No returns will be accepted without prior authorization from Fascomp. Return authorization must be in writing and from an authorized Fascomp representative.
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Cancellations</List.Header>
            <List.Description>
              Cancellation approval must come in writing from our sales office. Buyer assumes liability and makes payment to the seller for all work, whether in process or complete at the time of the request to cancel.
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Inspection</List.Header>
            <List.Description>
              All parts are manufactured to specifications in effect at the time of the sale. When inspecting, the main criteria should be product function ability. Fascomp reserves the right to change specifications provided the function ability of the item is not altered.
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Warranty</List.Header>
            <List.Description>
              All Fascomp products are warranted to the original purchaser. Products are to be free from defects in material or workmanship. Fascomp does not warrant the performance of its products for any particular purpose. Products found to be defective in the judgment of an authorized Fascomp representative will be replaced at no charge to the buyer. Fascomp?s responsibility ends upon 10 days after customer has received parts. Purchaser?s sole remedy with respect to any defective product sold by Fascomp is limited exclusively to the replacement of such defective product. The warranty is voided if repairs are attempted by anyone other than Fascomp without written authorization from a Fascomp Representative.
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Force Majeure</List.Header>
            <List.Description>
              Fascomp shall not be liable for failure to perform any of its obligations under this agreement when such failure is caused by the occurrence of any contingency beyond the reasonable control of such party including but not limited to unexpected difficulties when setting up or producing parts, fire, flood, strikes, war, accidents, insurrection, acts of God such as hurricanes, tornados, etc., orders of Governmental agencies or failure or delay of transportation. Fascomp?s maximum liability shall be limited to the cost of parts. The rights and obligations of all parties will be governed by and construed in accordance with the laws in the state of Florida. All disputes will be adjudicated in the state of Florida. In the event the buyer fails to comply with the terms and conditions of sale, buyer shall be liable for without limitation, all losses, costs and expenses that are a result of such default. If such contingency does occur, Fascomp may allocate deliveries amongst its customers as Fascomp see fit. A purchase order shall deem acceptance of these terms.
            </List.Description>
          </List.Item>
        </List>
      </Segment>
      <Message floating>
        <Message.Header textAlign="center">These terms supersede all other contracts or verbal representations, without exception.</Message.Header>
      </Message>
    </Container>
    <ScrollToTopOnMount />
  </div>
  )
}


export default PageTermsOfSale