import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Header,
  Image
} from 'semantic-ui-react'
/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Image className='mainLogo' src='/images/logo_trans_large.png' alt='Fascomp logo' centered={true} />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

export default HomepageHeading