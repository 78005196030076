var util = require('util');

var AppError = function (message, code, status, context, source) {
    Error.call(this);

    if (message !== null && typeof message === 'object')
    {
        this.message = message.message || "An unknown error has occurred";
        this.code = message.code || 'UnknownError';
        this.status = message.status || 500;
        this.context = message.context;
        this.source = message.source;
    }
    else
    {
        this.message = message || "An unknown error has occurred";
        this.code = code || 'UnknownError';
        this.status = status || 500;
        this.context = context;
        this.source = source;
    }
};

module.exports = AppError;