import { types, getParent, getSnapshot, applySnapshot, flow } from "mobx-state-tree"
import AppError from "../modules/apperror"
import { forEach } from "lodash";

export const User = types
  .model("User", {
    id: types.maybe(types.identifier()),
    username: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    company: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    emailCC: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    roles: types.optional(types.array(types.string), []),
    dateCreated: types.maybe(types.Date)
  })
  .views(self => ({

  }))
  .actions(self => {
    const setUsername = value => {
      self.username = value;
    };

    const setFirstName = value => {
      self.firstName = value;
    };

    const setLastName = value => {
      self.lastName = value;
    };

    const setCompany = value => {
      self.company = value;
    };

    const setEmail = value => {
      self.email = value;
    };

    const setEmailCC = value => {
      self.emailCC = value;
    };

    const setPhone = value => {
      self.phone = value;
    };

    const setPassword = value => {
      self.password = value;
    };

    const setRoles = value => {
      self.roles = value;
    };

    const save = () => {
      let response;

      let endpoint = '/api/user/create'

      if (self.id)
        endpoint = '/api/user/update';

      return fetch(endpoint, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(getSnapshot(self)), // data can be `string` or {object}!
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => {
        response = res;
        return res.json();
      })
      .then(resjson => {
        if (!response.ok) {

          if (response && response.status && (response.status == 401 || response.status == 403)) {
            self.shop.auth.setAuthRequested(true, () => {
              self.save();
            });
          }
          else {
            throw Object.assign(new AppError, resjson)
          }
        }
        console.log('Success:', JSON.stringify(response))
        return response;
      });
    }
    
    return ({
      setUsername,
      setFirstName,
      setLastName,
      setCompany,
      setEmail,
      setEmailCC,
      setPhone,
      setPassword,
      setRoles,
      save
    });
  });


  export const UserStore = types
  .model("UserStore", {
    isLoading: true,
    users: types.map(User)
  })
  .views(self => ({
    get shop() {
      return getParent(self)
    },
    get distributors() {
      const distributors = [];
      self.users.forEach(user => {
        if (user.roles.indexOf('Distributor') !== -1)
          distributors.push(user);
      });

      distributors.sort((a, b) => {
        if (a.company < b.company)
          return -1;
        else if (a.company > b.company)
          return 1;

        return 0;
      });

      return distributors;
    }

  }))
  .actions(self => {
    function markLoading(loading) {
      self.isLoading = loading;
    }

    

    const loadUsers = flow(function* loadUsers() {
      try {
        const json = yield self.shop.fetch("/api/users");
    
        if (json && json.status && (json.status == 401 || json.status == 403)) {
          self.shop.auth.setAuthRequested(true, () => {
            self.loadUsers();
          });
        }
        
        json.forEach(user => {
          console.log(user);
          self.users.put(user)
        });

        markLoading(false);
      } catch (err) {
        console.error("Failed to load users ", err)
      }
    });

    const deleteUser = flow(function* deleteUser(id) {
      try {

        const response = yield fetch('/api/user/delete', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify({ id }), // data can be `string` or {object}!
          headers:{
            'Content-Type': 'application/json'
          }
        });
    
        if (!response.ok) {
          if (response && response.status && (response.status == 401 || response.status == 403)) {
            self.shop.auth.setAuthRequested(true, () => {
              self.deleteUser(id);
            });
          }
          else {
            const json = yield response.json();
            throw Object.assign(new AppError, json);
          }
        }
        else {
          self.users.delete(id);
        }
        
      } catch (err) {
        console.error("Failed to delete user ", err)
        throw err;
      }
    });

    return {
      loadUsers,
      deleteUser
    }
  });