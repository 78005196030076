import React, { useEffect } from 'react'
import {
  Container,
  Divider,
  Grid,
  Image,
  Header,
  Icon,
  Segment,
  Card,
  List
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PageContactUs = () => {

  useEffect(() => {
    document.title = 'Contact Us - Fascomp Electronic Hardware';
  }, []);

  return (
    <Container>
      <Header as='h2' dividing textAlign='center'>
        <Icon name='envelope outline' />
        <Header.Content>Contact Us</Header.Content>
      </Header>
      <h3>We look forward to hearing from you</h3>
      <Segment.Group horizontal>
      <Segment>
        <h2>Orlando, FL Sales Office</h2>
        <List>
          <List.Item><a href="mailto:jason@fascomp.com">Jason Bertone</a> - Vice-President</List.Item>
          <List.Item><a href="mailto:erica@fascomp.com">Erica Kail</a> - General Manager</List.Item>
          <List.Item><a href="mailto:tom@fascomp.com">Tom Bello</a> - National Sales Manager</List.Item>
          <List.Item><a href="mailto:leiza@fascomp.com">Leiza Wilson</a> - Sales Manager</List.Item>
          <List.Item><a href="mailto:eddie@fascomp.com">Eddie Gonzalez</a> - Warehouse Manager</List.Item>
          <List.Item><a href="mailto:sales@fascomp.com">Sales Office</a> - General Sales</List.Item>
        </List>
      </Segment>
      <Segment>
        <h2>Naugatuck, CT Sales Office</h2>
        <List>
          <List.Item><a href="mailto:kyle@fascomp.com">Kyle Georgia</a> - President</List.Item>
          <List.Item><a href="mailto:Ashley@fascomp.com">Ashley Scagliarini</a> - Marketing / Sales</List.Item>
        </List>
      </Segment>
        </Segment.Group>

      <Divider horizontal style={{ padding: '2em 0em' }} />
      <Grid container stackable verticalAlign='middle'>

        <Grid.Row>
          <Grid.Column width={16}>
            <Card.Group centered={true} stackable={true}>
              <Card>
                <Image src='/images/orlando.jpg' size='medium' centered={true} />
                <Card.Content>
                  <Card.Header>
                    Orlando, FL
                  </Card.Header>
                  <Card.Description>
                    <div>1235 Tradeport Drive, Orlando, FL 32824</div>
                    <div>Tel: (407) 226-2112</div>
                    <div>Fax: (407) 226-3370</div>
                  </Card.Description>
                </Card.Content>
              </Card>

              <Card>
                <Image src='/images/naugatuck.jpg' size='medium' centered={true} />
                <Card.Content>
                  <Card.Header>
                    Naugatuck, CT
                  </Card.Header>
                  <Card.Description>
                    <div>88 Great Hill Rd., Naugatuck, Connecticut 06770</div>
                    <div>Tel: (203) 720-1146</div>
                    <div>Fax: (203) 720-1156</div>
                  </Card.Description>
                </Card.Content>
              </Card>

              <Card>
                <Image src='/images/mark-jason.jpg' size='medium' centered={true} />
                <Card.Content>
                  <Card.Header>
                    Mark Georgia & Jason Bertone
                  </Card.Header>
                </Card.Content>
              </Card>
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ScrollToTopOnMount />
    </Container>



  )
}

export default PageContactUs