import { types, getParent, flow, applySnapshot } from "mobx-state-tree"

export const LocationInventory = types
  .model("LocationInventory", {
    location: types.string,
    quantity: types.number,
  });

export const InventoryItem = types
  .model("InventoryItem", {
    partNumber: types.string,
    quantity: types.number,
    locationQuantities: types.maybe(types.array(LocationInventory)),
    material: types.maybe(types.string),
    plating: types.maybe(types.string),
    exactMatch: types.boolean,
    nonStandard: types.boolean
  });


export const InventoryStore = types
  .model("InventoryStore", {
    filteredInventory: types.array(InventoryItem),
  })
  .views(self => ({
    get shop() {
      return getParent(self)
    },
    get activePartGroup() {
      return self.shop.partGroupStore.activePartGroup;
    }
  }))
  .actions(self => {

    const clearFilteredInventory = () => {
      applySnapshot(self, { filteredInventory: [] });
    }

    const getFilteredInventory = flow(function* getFilteredInventory(partNumber) {
      if (partNumber.length < 1)
        return;

      try {
        const formData = new FormData();
        formData.append('partNumber', partNumber);

        const result = yield window.fetch("/api/filteredInventory", {
          method: 'POST',
          body: formData
        });
        
        if (result.status == 200) {
          const json = yield result.json();

          applySnapshot(self, { filteredInventory: json });
        }
        else
          throw new Error('Status:' + result.status)


      } catch (err) {
        console.error("Failed to load inventory ", err)
      }
    })

    return {
      getFilteredInventory,
      clearFilteredInventory
    };
  });