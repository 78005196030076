import { types, getEnv } from "mobx-state-tree"
import { reaction } from "mobx"
//import { ItemStore } from "./ItemStore"
import { CartStore } from "./CartStore"
import { CrossRefStore } from "./CrossRefStore"
import { ViewStore } from "./ViewStore"
//import { PhotoStore } from "./PhotoStore"
//import { ProjectStore } from "./ProjectStore"
import { PartGroupStore } from "./PartGroupStore"
import { QuoteStore } from "./QuoteStore"
import { AuthStore } from "./AuthStore"
import { InventoryStore } from "./InventoryStore"
import { UserStore } from "./UserStore"

export const ShopStore = types
    .model("ShopStore", {
      /*
        itemStore: types.optional(ItemStore, {
            items: {}
        }),
        */
        auth: types.optional(AuthStore, {

        }),
        user: types.optional(UserStore, {
            users: {}
        }),
        quotes: types.optional(QuoteStore, {
            rfqQuotes: {},
            crossQuotes: {}
        }),
        cart: types.optional(CartStore, {
            entries: []
        }),
        crossRef: types.optional(CrossRefStore, {
            entries: [{}, {}, {}]
        }),
        partGroupStore: types.optional(PartGroupStore, {
            partGroups: {}
        }),
        inventory: types.optional(InventoryStore, { filteredInventory: []}),
        view: types.optional(ViewStore, {}),
        lastSelectedSearch: types.optional(types.string, 'Unknown')
    })
    .views(self => ({
        get fetch() {
            return getEnv(self).fetch
        },
        get alert() {
            return getEnv(self).alert
        },
        get isLoading() {
            return self.partGroupStore.isLoading
        },
        get isLoadingSingle() {
            return self.partGroupStore.isLoadingSingle
        },
        
      /*
        get items() {
            return self.itemStore.items
        },
        get projects() {
            return self.projectStore.projects
        },
        */
        get partGroups() {
            return self.partGroupStore.partGroups
        },
      /*
        get sortedProjects() {
            return self.projectStore.sortedProjects
        },
        get sortedAvailableItems() {
            return self.itemStore.sortedAvailableItems
        },
        get photos() {
            return self.photoStore.photos
        },
        get sortedPhotos() {
            return self.photoStore.sortedPhotos
        },

        get opaqueModuleOutput() {
            return {
                cartTotalQuantity: self.cart && self.cart.totalQuantity ? self.cart.totalQuantity : 0,
                projectCount: self.projectStore && self.projectStore.projectCount ? self.projectStore.projectCount : 0
            }
        }
         */
    }))
    .actions(self => {


        function afterCreate() {
            //self.itemStore.loadItems();
            //self.photoStore.loadPhotos();
            // self.partGroupStore.loadPartGroups();
          /*
          self.opaqueModule = new OpaqueModule();

            self.opaqueModule.bindOutputListener((data) => {
                self.setOpaqueModuleStatus('Number of projects is ' + (data.numberOfProjectsIsEven ? 'even. ' : 'odd. ')
                    + 'Number of cart items is ' + (data.numberOfCartItemsIsEven ? 'even. ' : 'odd. '))
            });

            reaction(
                () => self.opaqueModuleOutput,
                data => {
                    self.opaqueModule.onExternalInput(data)
                }
            )
            */
        }

        function setLastSelectedSearch(lastSelectedSearch) {
          self.lastSelectedSearch = lastSelectedSearch;
        }
        function setOpaqueModuleStatus(status)
        {
            self.opaqueModuleStatus = status;
        }

        return {
            afterCreate,
            setLastSelectedSearch,
            setOpaqueModuleStatus,
        }
    });