import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import {
  Container,
  Table,
  Icon,
  Header,
  Label
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PageTorqueValues = () => {
  useEffect(() => {
    document.title = 'Thread Torque Values - Fascomp Electronic Hardware';
  }, []);

  return (
  <div>
    <Container>
      <Header as='h2' dividing textAlign='center'>
        <Icon name='info circle' />
        <Header.Content>Thread Torque Values</Header.Content>
      </Header>

      <Table celled unstackable columns={4}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="6">English Threads</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="6">This table is offered as the suggested maximum torque for Fascomp threaded products</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="6">Torque Values = inch-pounds</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Thread</Table.HeaderCell>
            <Table.HeaderCell>Stainless</Table.HeaderCell>
            <Table.HeaderCell>Brass</Table.HeaderCell>
            <Table.HeaderCell>Aluminum</Table.HeaderCell>
            <Table.HeaderCell>Steel</Table.HeaderCell>
            <Table.HeaderCell>Nylon</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>0-80</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>2-56</Table.Cell>
            <Table.Cell>2.3</Table.Cell>
            <Table.Cell>1.8</Table.Cell>
            <Table.Cell>1.2</Table.Cell>
            <Table.Cell>2.5</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>4-40</Table.Cell>
            <Table.Cell>5.0</Table.Cell>
            <Table.Cell>4.1</Table.Cell>
            <Table.Cell>2.7</Table.Cell>
            <Table.Cell>4.4</Table.Cell>
            <Table.Cell>1.1</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>6-32</Table.Cell>
            <Table.Cell>9.4</Table.Cell>
            <Table.Cell>7.7</Table.Cell>
            <Table.Cell>5.1</Table.Cell>
            <Table.Cell>8.5</Table.Cell>
            <Table.Cell>2.0</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>8-32</Table.Cell>
            <Table.Cell>19.2</Table.Cell>
            <Table.Cell>15.7</Table.Cell>
            <Table.Cell>10.4</Table.Cell>
            <Table.Cell>17.3</Table.Cell>
            <Table.Cell>3.9</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>10-24</Table.Cell>
            <Table.Cell>22.4</Table.Cell>
            <Table.Cell>18.2</Table.Cell>
            <Table.Cell>13.4</Table.Cell>
            <Table.Cell>20.2</Table.Cell>
            <Table.Cell>6.2</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>10-32</Table.Cell>
            <Table.Cell>31.0</Table.Cell>
            <Table.Cell>25.1</Table.Cell>
            <Table.Cell>18.5</Table.Cell>
            <Table.Cell>28.6</Table.Cell>
            <Table.Cell>7.1</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1/4 20</Table.Cell>
            <Table.Cell>73.0</Table.Cell>
            <Table.Cell>57.0</Table.Cell>
            <Table.Cell>42.0</Table.Cell>
            <Table.Cell>63.0</Table.Cell>
            <Table.Cell>7.6</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1/4 28</Table.Cell>
            <Table.Cell>91.0</Table.Cell>
            <Table.Cell>73.5</Table.Cell>
            <Table.Cell>54.2</Table.Cell>
            <Table.Cell>88.0</Table.Cell>
            <Table.Cell>19.5</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5/16 18</Table.Cell>
            <Table.Cell>131.0</Table.Cell>
            <Table.Cell>103.1</Table.Cell>
            <Table.Cell>76.5</Table.Cell>
            <Table.Cell>125.0</Table.Cell>
            <Table.Cell>32.0</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5/16 24</Table.Cell>
            <Table.Cell>139.1</Table.Cell>
            <Table.Cell>112.2</Table.Cell>
            <Table.Cell>83.5</Table.Cell>
            <Table.Cell>135.0</Table.Cell>
            <Table.Cell>33.0</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3/8 16</Table.Cell>
            <Table.Cell>233.0</Table.Cell>
            <Table.Cell>188.0</Table.Cell>
            <Table.Cell>140.0</Table.Cell>
            <Table.Cell>205.0</Table.Cell>
            <Table.Cell>45.0</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3/8 24</Table.Cell>
            <Table.Cell>255.0</Table.Cell>
            <Table.Cell>208.0</Table.Cell>
            <Table.Cell>153.0</Table.Cell>
            <Table.Cell>227.0</Table.Cell>
            <Table.Cell>47.0</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table celled unstackable columns={4}>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="6">Metric Threads</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="6">This table is offered as the suggested maximum torque for Fascomp threaded products</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="6">Torque Values = inch-pounds</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Thread</Table.HeaderCell>
            <Table.HeaderCell>Stainless</Table.HeaderCell>
            <Table.HeaderCell>Brass</Table.HeaderCell>
            <Table.HeaderCell>Aluminum</Table.HeaderCell>
            <Table.HeaderCell>Steel</Table.HeaderCell>
            <Table.HeaderCell>Nylon</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>M2 X 0.4</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>M2.5 X 0.45</Table.Cell>
            <Table.Cell>2.2</Table.Cell>
            <Table.Cell>1.7</Table.Cell>
            <Table.Cell>1.1</Table.Cell>
            <Table.Cell>2.4</Table.Cell>
            <Table.Cell>Not Rated</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>M3 X 0.5</Table.Cell>
            <Table.Cell>4.9</Table.Cell>
            <Table.Cell>4.0</Table.Cell>
            <Table.Cell>2.6</Table.Cell>
            <Table.Cell>4.3</Table.Cell>
            <Table.Cell>1.1</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>M3.5 X 0.6</Table.Cell>
            <Table.Cell>6.2</Table.Cell>
            <Table.Cell>5.2</Table.Cell>
            <Table.Cell>4.0</Table.Cell>
            <Table.Cell>5.6</Table.Cell>
            <Table.Cell>1.5</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>M4 X 0.7</Table.Cell>
            <Table.Cell>9.3</Table.Cell>
            <Table.Cell>7.6</Table.Cell>
            <Table.Cell>5.0</Table.Cell>
            <Table.Cell>8.4</Table.Cell>
            <Table.Cell>1.9</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>M5 X 0.8</Table.Cell>
            <Table.Cell>19.0</Table.Cell>
            <Table.Cell>15.6</Table.Cell>
            <Table.Cell>10.3</Table.Cell>
            <Table.Cell>17.2</Table.Cell>
            <Table.Cell>3.8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>M6 X 1.0</Table.Cell>
            <Table.Cell>30.0</Table.Cell>
            <Table.Cell>23.0</Table.Cell>
            <Table.Cell>16.0</Table.Cell>
            <Table.Cell>25.0</Table.Cell>
            <Table.Cell>6.5</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>M8 X 1.2</Table.Cell>
            <Table.Cell>48.0</Table.Cell>
            <Table.Cell>33.0</Table.Cell>
            <Table.Cell>23.0</Table.Cell>
            <Table.Cell>38.0</Table.Cell>
            <Table.Cell>7.0</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Container>
    <ScrollToTopOnMount />
  </div>
  )
}

export default PageTorqueValues