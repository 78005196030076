import React from "react"
import { observer, inject } from "mobx-react"

const Projects = inject("shop")(
    observer(({ shop }) => (
        <section className="Page-items">
            <h1>Your projects</h1>
            <ol>
                {shop.sortedProjects.map(project => <ProjectEntry key={project.id} project={project} />)}
            </ol>
        </section>
    ))
)

const ProjectEntry = inject("shop")(
    observer(({ project, shop }) => (
        <li>
            <a
                href={`/project/${project.id}`}
                onClick={e => {
                    e.preventDefault()
                    shop.view.openProjectPage(project)
                    return false
                }}
            >
                {project.name} - {project.item.name}
            </a>
        </li>
    ))
)



export default Projects